<template>
    <div>
    <div class="modal fade errorModal success" tabindex="-1" ref="errorModal" aria-hidden="true">

        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <i class="fa fa-circle-check" aria-hidden="true"></i>
            </div>
            <div class="modal-body">
                <h5 class="modal-title">{{ $t(message) }}</h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-new" @click="toggle()">Close</button>
            </div>
            </div>
        </div>

    </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import '@/assets/css/errorModal.css';

export default {
    name: 'SuccessModal',
    data() {
        return {
            errorModal: null,
            errorModalOpen: false
        }
    },
    props: {
        message: String,
        openModal: Boolean,
        resetProps: {
            type: Function
        }
    },
    watch: {
        $props: {
            handler() {
                this.toggle();
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        toggle() {
            if (this.$props.openModal) {
                if (!this.errorModal) {
                    this.errorModal = new Modal(this.$refs.errorModal, {
                        backdrop: 'static',
                        keyboard: false
                    });
                }

                if (this.errorModalOpen) {
                    this.errorModal.hide();
                    this.errorModalOpen = false;

                    if (this.resetProps) {
                        this.resetProps();
                    }

                } else {
                    this.errorModal.show();
                    this.errorModalOpen = true;
                }
            }
        }
    }
};
</script>  