import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import i18n from './boot/i18n';
import VueCookies from 'vue-cookies';
import VueNumber from 'vue-number-animation';

import '@/assets/css/actualites.css';
import '@/assets/css/apropos.css';
import '@/assets/css/bureauxcontacts.css';
import '@/assets/css/colors.css';
import '@/assets/css/doublage.css';
import '@/assets/css/emploi.css';
import '@/assets/css/fonts.css';
import '@/assets/css/media.css';
import '@/assets/css/services.css';
import '@/assets/css/studios.css';
import '@/assets/css/tech.css';
import '@/assets/css/titrage.css';
import '@/assets/css/contactpage.css';
import '@/assets/css/terms.css';

import '@/assets/css/animate.css';
import "bootstrap/dist/css/bootstrap.min.css";

import '@/assets/css/main.css';
import '@/assets/css/normalize.css';
import '@/assets/css/menu-header.css';
import '@/assets/css/stylechart.css';


// used on remake
import 'bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import '@/assets/css/sidebar.css';
import '@/assets/css/footer.css';

import AOS from "aos";
import "aos/dist/aos.css";
import VueMatomo from 'vue-matomo'
import configStatic from './boot/configStatic';

const APP = createApp(App)
  .use(VueNumber)
  .use(VueMatomo, {
    host: 'https://stats.dubbing-brothers.com/',
    siteId: 13,
    router: router,
    userId: undefined,
    domains: '*.dubbing-brothers.com',
    //disableCookies: this.$cookies.get("cookies_audience") !== "true"
    disableCookies: window.localStorage.getItem(configStatic.COOKIE_PREFERENCES_TRACKING_ACCEPTED) !== "true"
  })

i18n().then((i18n) => {
  APP.use(i18n).use(router).use(VueCookies).mount('#app');

  APP.config.globalProperties.i18n = i18n;
  AOS.init();
  document.title = i18n.global.t('mzihxzxv');
});

export default APP;
