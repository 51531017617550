<template>
  <div class="singlePage terms">
      <main>
        <div class="container-fluid px-4 m-0">
            <div class="row justify-content-center">
              <div class="col-11" >
                <span v-html="$t('k5uuz2rm')"></span>

                <span style="display: none;">
                                    <p>Cette page précise aux utilisateurs du site <a class="buttonAction p-2 py-0 rounded-2" href="www.dubbing-brothers.com">www.dubbing-brothers.com</a> (le « Site ») l’identité des différents intervenants impliqués dans sa réalisation et son
                                    maintien en activité.</p>

                                    <p><b>1. Mentions légales </b></p><br />
                                    <b>Editeur </b>: la société DUBBING BROTHERS - Société par actions simplifiée <br />
                                    <b>Siège social :</b> 19 rue de la Montjoie - 93210 La Plaine Saint Denis <br />
                                    <b>Capital social :</b> 1 550 000,00 euros <br />
                                    <b>Immatriculée au RCS de Bobigny sous le numéro :</b> 350 436 028<br />
                                    <b>Numéro de TVA :</b> FR77350436028 <br />
                                    <b>Téléphone :</b> +33 (1) 41 62 74 00 <br />
                                    <b>Email :</b> contact@dubbing-brothers.com <br />
                                    <br />
                                    <b>Directeur de publication :</b> Mathieu Taieb, Head of operations and Sales
                                    <br />
                                  <p>
                                    <b>Hébergement du site :</b> la société DUBBING BROTHERS<br />
                                    <b>Siège social :</b> 19 rue de la Montjoie - 93210 La Plaine Saint Denis <br />
                                    <b>Téléphone :</b> 01 41 62 74 00</p>
                                    <br />
                                  <p><b>2. Droit des marques/droits d'auteur/Propriété Intellectuelle</b> <br/></p>
                    <p>
                    La structure générale, ainsi que les logiciels, textes, images animées ou non, sons, savoir-faire, éléments graphiques, marques, logos, bases de données et tous autres éléments composants le Site sont la propriété exclusive de Dubbing Brothers, de ses partenaires et clients.</p><br/>
                    <p>
                    Il est interdit à l'utilisateur du Site ou à tout tiers, sans que cette liste soit limitative, de copier, reproduire, représenter, diffuser, vendre, publier, exploiter de
                    toute autre manière et diffuser dans un autre format sous forme électronique ou autres, ou tenter de modifier tout ou une partie des éléments et informations
                    figurant sur le Site et les sites qui y sont rattachés par des liens hypertextes, sans l'autorisation préalable et écrite de Dubbing Brothers. 
                                    </p>

                                    <p>En conséquence, toute reproduction ou représentation totale ou partielle de ce Site, des sites qui y sont rattachés par des liens hypertextes, et/ou de leurs
                    éléments, par quelque procédé que ce soit, sans l'autorisation expresse et écrite de Dubbing Brothers constituerait une contrefaçon sanctionnée par le Code de
                    la propriété intellectuelle. <br/>
                                Tout utilisateur, propriétaire d'un site internet ou non, qui souhaiterait utiliser un lien hypertexte de renvoi vers le Site doit préalablement et nécessairement
                    demander l'autorisation à Dubbing Brothers en utilisant l'adresse mail de contact mentionnée dans les présentes. Tout lien devra être retiré à la simple et unique
                    demande de Dubbing Brothers. </p>
                    
                </span>
              </div>  
            </div>  
         </div>
      </main>
  </div>
</template>

<script>
export default {
  name: "LegalTermsPage",
}
</script>
