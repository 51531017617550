<template>
    <div id="contactPage" class="singlePage">
        <main>
            <div class="background"></div>
            <div class="container-fluid px-4 m-0">
                <div class="row justify-content-center">
                    <div class="col-11 p-0">
                        <h1>{{$t('i0wsib6i')}}</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid mb-5">
                <div class="row justify-content-center pb-5">
                    <div class="col-11">
                        <div class="row g-3">
                            <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 order-2 order-sm-2 order-md-2  order-lg-1 order-xl-1">
                                <form novalidate>
                                    <div class="mb-1">
                                        <label class="form-label">{{$t('g8tmyt8t')}}<span class="mandatoryStar">*</span></label>
                                        <select class="form-select" v-model="contactForm.location">
                                            <option v-for="location in locations" v-bind:value="location.name" v-bind:key="location.id">
                                                {{ location.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mb-1">
                                        <label for="validationCustom01" class="form-label">{{$t('0vjr3a0s')}}<span class="mandatoryStar">*</span></label>
                                        <input v-model="contactForm.lastName" type="text" class="form-control" id="validationCustom01" :placeholder="$t('0vjr3a0s')" required>
                                        <div class="valid-feedback">{{$t('51v0cnc9')}}</div>
                                    </div>

                                    <div class="mb-1">
                                        <label for="validationCustom02" class="form-label">{{$t('nerc2brw')}}<span class="mandatoryStar">*</span></label>
                                        <input v-model="contactForm.firstName" type="text" class="form-control" id="validationCustom02" :placeholder="$t('nerc2brw')" required>
                                        <div class="valid-feedback">{{$t('51v0cnc9')}}</div>
                                    </div>

                                    <div class="mb-1">
                                        <label for="validationCustom03" class="form-label">{{$t('jw37yx2h')}}<span class="mandatoryStar">*</span></label>
                                        <input v-model="contactForm.email" type="email" class="form-control" id="validationCustom03" :placeholder="$t('jw37yx2h')" required>
                                        <div class="invalid-feedback">{{$t('ye12kplg')}}</div>
                                    </div>

                                    <div class="mb-1">
                                        <label for="validationCustom03" class="form-label">{{$t('ye4zup2r')}}</label>
                                        <input v-model="contactForm.society" type="text" class="form-control" id="validationCustom04" :placeholder="$t('ye4zup2r')" required>
                                        <div class="invalid-feedback">{{ $t('0vlyw6js') }}</div>
                                    </div>

                                    <div class="mb-4">
                                        <label for="validationCustom05" class="form-label">{{$t('uyspnafr')}}<span class="mandatoryStar">*</span>:</label>
                                        <select @change="handleChange()" v-model="contactForm.reason" class="form-select" aria-label="Default select example">
                                            <option v-bind:value="$t('d3id87t8')">{{$t('d3id87t8')}}</option>
                                            <option v-bind:value="$t('otjjcjkj')">{{$t('otjjcjkj')}}</option>
                                            <option v-bind:value="$t('xxebk98b')">{{$t('xxebk98b')}}</option>
                                            <option v-bind:value="$t('8u1aqg9s')">{{$t('8u1aqg9s')}}</option>
                                        </select>

                                        <span class="inputWarning" v-show="contactForm.reasonKey == 'job'">
                                            <i class="fas fa-warning"></i>{{ $t('t3j6e3jk')}}
                                            <a class="btn-primary gold-btn" @click="navigate('/jobs')">{{ $t('m2ygmyl0')}}</a>
                                        </span>
                                    </div>

                                    <div class="mb-1">
                                        <label for="validationCustom03" class="form-label">{{ $t('z4wdrw3p') }}</label>
                                        <textarea v-model="contactForm.message" class="form-control" :placeholder="$t('z4wdrw3p')" id="floatingTextarea2" style="height: 100px"></textarea>
                                    </div>

                                    <div>
                                        <div class="row py-3">
                                            <div class="col-lg-6 col">
                                                <div class="d-flex align-items-center justify-content-start my-1"  @click="getChallenge()">
                                                    <img :src="captchaImage">
                                                    <div class="ms-2 d-flex align-items-center justify-content-start">
                                                        <i v-if="captchaLoading" class="fas fa-circle-notch fa-spin fa-fw" style="color:var(--gold);"></i>
                                                        <i v-if="!captchaLoading && captchaImage" class="fa-solid fa-arrows-rotate redoCaptcha fa-fw"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col">
                                                <input type="text" class="form-control my-1" v-model="contactForm.captchaCode" placeholder="Captcha code" required>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mb-1">
                                        <p class="info">
                                            <span class="mandatoryStar"></span>{{ $t('umer32bh') }}<br/>
                                            <span v-html="$t('mrpwghd0')"></span><br/>
                                            {{ $t('xw11rrnt') }}
                                        </p>
                                    </div>
                                    <button @click="postContactRequest()" :disabled="!contactForm.captchaCode || contactForm.reasonKey == 'job'" class="w-100 btn-primary gold-btn" type="button">{{$t('r54blv4d')}}</button>
                                </form>
                            </div>                            
                            <div class="col-sm-12 col-md col-lg col-xl order-1 order-sm-1 order-md-1 order-lg-2 order-xl-2">
                                <div class="imgWrapper h-100">
                                    <img src="../assets/img/img/studiofrance.jpg" alt="studio">
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <ErrorModal :message="errorMessage" :openModal="errorModalOpen" :resetProps="handleErrorModalResetProps" />
        <SuccessModal :message="successMessage" :openModal="successModalOpen" :resetProps="handleSuccessModalResetProps" />
    </div>
</template>

<script>
import configStatic from '@/boot/configStatic';
import $axios from '@/boot/axios';
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/SuccessModal.vue';

export default {
    name: "ContactPage",
    components: {
        ErrorModal,
        SuccessModal
    },
    data() {
        return {
            selectedLocationId: this.$route.params.id ? this.$route.params.id : configStatic.BRANCHES.FR_BRANCH,
            locations: configStatic.CONTACT_LOCATIONS_DATA,
            captchaImage: null,
            captchaLoading: false,
            formLoading: false,
            successModalOpen: false,
            errorMessage: null,
            errorModalOpen: false,
            successMessage: 'cg938byq',
            contactForm: {
                location: "",
                lastName: "",
                firstName: "",
                email: "",
                society: "",
                reason: "",
                message: "",
                reasonKey: "",
                branchId: configStatic.SELECTED_BRANCH_ID,
                captchaCode: null
            }
        }
    },
    beforeMount(){
        this.getChallenge()
    },
    mounted() {
        this.contactForm.location = configStatic.CONTACT_LOCATIONS_DATA.find(l => l.id == this.selectedLocationId).name;
    },
    methods: {
        getChallenge() {
            this.captchaLoading = true;
            $axios.get('/captcha-challenge').then((response) => {
                this.captchaLoading = false;
                this.captchaImage = response.data.captchaImage;
            }).catch(error => {
                this.captchaLoading = false;
                this.errorModalOpen = true;
                this.errorMessage = error.message;
            });
        },
        handleChange(){
            if (this.contactForm.reason === this.$t('xxebk98b'))
                this.contactForm.reasonKey = "job"
            else
                this.contactForm.reasonKey = "";
        },
        navigate(page, hash = ''){
            if (this.$router.currentRoute._value.name.toLowerCase() !== page) {
                this.$router.push({ path: page, hash: hash });
            }
        },
        postContactRequest() {
            this.contactForm.branchId = configStatic.CONTACT_LOCATIONS_DATA.find(l => l.name == this.contactForm.location).id;

            $axios.post(`/contact-messages`, this.contactForm).then(() => {
                this.contactForm = {
                    location: "",
                    lastName: "",
                    firstName: "",
                    email: "",
                    society: "",
                    reason: "",
                    message: "",
                    captchaCode: null,
                    branchId: null
                };
                this.successModalOpen = true;
                this.getChallenge();
            }).catch(error => {
                this.formLoading = false;
                this.errorModalOpen = true;
                this.errorMessage = error.message;
            });
        },
        handleErrorModalResetProps() {
            this.errorModalOpen = false;
            this.errorMessage = null;
        },
        handleSuccessModalResetProps() {
            this.successModalOpen = false;
            this.successMessage = 'cg938byq';
        }
    }
}
</script>