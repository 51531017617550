import { createRouter, createWebHashHistory } from 'vue-router';
// import session from '@/boot/session';
// import LoginPage from '@/views/LoginPage.vue';

import CookieTermsPage from '@/views/CookieTermsPage.vue';
import LegalTermsPage from '@/views/LegalTermsPage.vue';
import PrivacyTermsPage from '@/views/PrivacyTermsPage.vue';
import ContactPage from '@/views/ContactPage.vue';
import BlogPage from '@/views/BlogPage.vue';
import BlogPostPage from '@/views/BlogPostPage.vue';
import ContactOfficesPage from '@/views/ContactOfficesPage.vue';
import ReferencesPage from '@/views/ReferencesPage.vue';
import ServicesPage from '@/views/ServicesPage.vue';
import StudiosPage from '@/views/StudiosPage.vue';
import AboutPage from '@/views/AboutPage.vue';
import JobsPage from '@/views/JobsPage.vue';
import HomePage from '@/views/HomePage.vue';
import TermsRecruit from '@/views/TermsRecruit.vue';

const routes = [
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: LoginPage,
  //   meta: { isPublic: true },
  // },
  {
    path: '/',
    component: () => import('../layout/MainLayout.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: HomePage,
        meta: { isPublic: true },
      },
      {
        path: '/contact/:id?',
        name: 'Contact',
        component: ContactPage,
        meta: { isPublic: true },
      },
      {
        path: '/blog',
        name: 'Blog',
        component: BlogPage,
        meta: { isPublic: true },
      },
      {
        path: '/blog/post/:id',
        name: 'Blog Post',
        component: BlogPostPage,
        meta: { isPublic: true },
      },
      {
        path: '/about',
        name: 'About',
        component: AboutPage,
        meta: { isPublic: true },
      },
      {
        path: '/contact-offices',
        name: 'Contact Offices',
        component: ContactOfficesPage,
        meta: { isPublic: true },
      },
      {
        path: '/references',
        name: 'References',
        component: ReferencesPage,
        meta: { isPublic: true },
      },
      {
        path: '/services',
        name: 'Services',
        component: ServicesPage,
        meta: { isPublic: true },
      },
      {
        path: '/studios',
        name: 'Studios',
        component: StudiosPage,
        meta: { isPublic: true },
      },
      {
        path: '/jobs',
        name: 'Jobs',
        component: JobsPage,
        meta: { isPublic: true },
      },
      {
        path: '/cookie-terms',
        name: 'Cookie Terms',
        component: CookieTermsPage,
        meta: { isPublic: true },
      },
      {
        path: '/legal-terms',
        name: 'Legal Terms',
        component: LegalTermsPage,
        meta: { isPublic: true },
      },
      {
        path: '/privacy-terms',
        name: 'Privacy Terms',
        component: PrivacyTermsPage,
        meta: { isPublic: true },
      },
      {
        path: '/recruitment-terms',
        name: 'Recruitment Terms',
        component: TermsRecruit,
        meta: { isPublic: true },
      }
    ],
  },
  {
    path: '/:catchAll(.*)*',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/NotFoundPage.vue'),
  },
];

const Router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({
            el: to.hash,
            top: 0,
            behavior: 'smooth',
          });
        } else {
          resolve({
            top: 0,
            behavior: 'smooth',
          });
        }
      }, 500)
    })
  }
});

Router.beforeEach(async (to, from, next) => {
  if (to.meta.isPublic) {
    next();
    return;
  }
  else if (to.path === '/') {
    next({ path: '/home' });
    return;
  } else {
    next();
    return;
  }

  // if (to.meta.isPublic) {
  //   if (to.path === '/login') {
  //     session.cleanSession();
  //   }
  //   next();
  //   return;
  // }

  // if (to.path === '/') {
  //   next({ path: '/home' });
  //   return;
  // }

  // if (session.getUser()) {
  //   next();
  // } else {
  //   let result = await session.restoreSession();

  //   if (result.success) {
  //     next({ path: to.fullPath });
  //   } else {
  //     next({ path: '/login' });
  //   }
  // }
});

export default Router;
