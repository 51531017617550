import $axios from './axios';
import config from './config';
import role from './role';
import APP from './../main';
import configStatic from './configStatic';

let _user = null;
let _token = null;
let _branches = null;

class Session {
  subscribers = [];

  addEventSubscriber(func)
  {
    this.subscribers.push(func);
  }

  removeEventSubscriber(func)
  {
    if (this.subscribers.indexOf(func) >= 0)
      this.subscribers.splice(this.subscribers.indexOf(func), 1);
  }

  sendEvent(eventType, data)
  {
    this.subscribers.forEach(s => s(eventType, data));
  }

  setUser(user) {
    _user = user;
  }

  setToken(token) {
    _token = token;
    window.sessionStorage.setItem('DB_TOKEN', token);
    $axios.setHeader('auth-token', token);
  }

  setBranches(branches) {
    _branches = branches;
  }

  setBranchId(branchId, refreshAccess = false) {
    APP.$cookies.set(`branch_${config.APP_ID}`, branchId, '1d', '/', config.COOKIE_APP_DOMAIN, config.COOKIE_SECURED);

    if (refreshAccess) role.initAccess(this.getBranch());
  }

  setLanguage(language) {
    window.localStorage.setItem('DB_LANGUAGE', language);

    if (APP.config.globalProperties.i18n) {
      APP.config.globalProperties.i18n.global.locale = language;
    }

    this.sendEvent("LANGUAGE_CHANGED", {"language" : language});
  }

  getToken() {
    return _token;
  }

  getTokenFromSessionStorage() {
    return window.sessionStorage.getItem('DB_TOKEN');
  }

  getUser() {
    return _user;
  }

  getUserFullname() {
    const u = this.getUser();
    return u ? `${u.firstname} ${u.lastname}` : null;
  }

  getUserId() {
    const u = this.getUser();
    return u ? u.id : null;
  }

  getBranches() {
    return _branches ?? [];
  }

  getBranchId() {
    return APP.$cookies.get(`branch_${config.APP_ID}`);
  }

  getBranch() {
    let branches = this.getBranches();
    return branches.find((branch) => branch.id == this.getBranchId());
  }

  getLanguage() {
    let langCode = window.localStorage.getItem('DB_LANGUAGE');
    return langCode ?? config.DEFAULT_LANGUAGE;
  }

  async restoreSession() {
    _token = this.getTokenFromSessionStorage();

    try {
      $axios.setHeader('auth-token', _token);
      $axios.setHeader('app-code', configStatic.APP_CODE);
      const response = await $axios.get(`users/currentuserdetails`);
      if (response.status != 200) {
        return { success: false };
      }

      this.init(response.data, true);
      return { success: true };
    } catch (error) {
      if (config.DEBUG) console.log(error);
      return { success: false, error: error };
    }
  }

  cleanSession() {
    _token = null;
    _user = null;
    _branches = null;
    $axios.removeHeader('auth-token');
    $axios.removeHeader('app-code');
    window.sessionStorage.removeItem('DB_TOKEN');
    role.resetAccess();
  }

  init(data, keepCurrentBranch = false) {
    let branches = data.branches;
    if (!branches) {
      let permission = data.permissions.find((p) => p.app_id == config.APP_ID);
      if (permission && permission.roles) {
        branches = permission.roles.map((r) => ({ id: r.branch_id, name: r.branch_name, role: r.name }));
      }
    }

    if (!branches) return;
    let branchId = branches[0].id;

    if (keepCurrentBranch) {
      let currentBranchId = this.getBranchId();
      branchId = currentBranchId && branches.some((branch) => branch.id == currentBranchId) ? currentBranchId : branchId;
    }

    this.setUser(data.user ? data.user : data);
    this.setBranchId(branchId);
    this.setBranches(branches);
    role.initAccess(this.getBranch());

    if (data.token) this.setToken(data.token);
  }

  isLocalhost() {
    return window.document.location.hostname == 'localhost';
  }
}

export default new Session();
