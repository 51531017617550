<template>
    <div id="servicesPage" class="singlePage">
        <main class="sectionServices content">
            <div class="background first"></div>

            <div class="px-4 m-0">
              <div class="row justify-content-center">
                <div class="col-11 p-0">
                    <h1>{{$t('lciv9fmf')}}</h1>
                    <p>{{$t('c3tkklru')}}</p>
                </div>
              </div>
            </div>

            <div class="px-4 mb-5 doublage">
                <div class="row justify-content-center">
                    <div class="col-11 p-0">
                        <div class="row mt-5">
                            <div class="col-12 mt-5">
                                <h1 id="doublageSection">{{$t('peaan5wm')}}</h1>
                            </div>
                        </div>

                        <div class="row serviceItem g-3 doublage">
                            <div class="col-sm-12 col-md-12 col-lg col-xl d-flex flex-column shadowWrapper" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="250">
                                <div data-aos="fade-in" data-aos-duration="1500" data-aos-delay="250" class="imgWrapper w-100">
                                    <img src="../assets/img/image_doublage.jpg" alt="studiopicture" />
                                </div>

                                <div class="col bg-black mt-3 p-5 d-flex align-content-center align-items-center">
                                    <div>
                                        <h4><b>{{$t('mw2oumz9')}}</b></h4>
                                        <p>{{$t('eklp0jmd')}}</p>
                                    </div>
                                </div>

                                <div class="col bg-black mt-3 p-5 d-flex align-content-center align-items-center">
                                    <div class="d-flex flex-column">
                                        <h4>{{$t('ma7fiim0')}}</h4>
                                        <p>{{ $t('bih0451n') }}</p>
                                        <br><br>
                                    </div>
                                </div>
                         

                                <div class="shadow"></div>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg col-xl d-flex flex-column shadowWrapper">
                                <div data-aos="fade-in" data-aos-duration="1500" data-aos-delay="250" class="imgWrapper w-100">
                                    <img src="../assets/img/img/studiofrance.jpg" alt="studiopicture">
                                </div>

                                <div class="col bg-black mt-3 p-5 d-flex align-content-center align-items-center">
                                    <div>
                                        <h4><b>{{$t('nj5roqbc')}}</b></h4>
                                        <p>{{$t('1f4f4d6p')}}</p>
                                        <br/>
                                        <!-- <router-link to="/studios" class="buttonAction mt-3 w-100" style="display: inline-block;">{{$t('uyihl1z0')}}</router-link> -->
                                    </div>
                                </div>
                                
                                <div class="col bg-black mt-3 p-5 d-flex align-content-center align-items-center">
                                    <div class="d-flex flex-column w-100">
                                        <h4><b>{{$t('5s242579')}}</b></h4>
                                        <router-link to="/studios" class="buttonAction mt-3 w-100" style="display: inline-block;">{{$t('uyihl1z0')}}</router-link>
                                        <br>
                                    </div>
                                </div>

                                <div class="shadow"></div>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg col-xl d-flex flex-column shadowWrapper">
                                <div data-aos="fade-in" data-aos-duration="1500" data-aos-delay="250" class="imgWrapper w-100">
                                    <img src="../assets/img/img/rythmo-one.jpg" alt="studiopicture">
                                </div>
                                
                                <div class="col bg-black mt-3 p-5 d-flex align-content-center align-items-center">
                                    <div class="d-flex flex-column">
                                        <h4><b>{{$t('vagt4trw')}}</b></h4>
                                            <p>{{$t('zpqqqjpu')}}</p>
                                    </div>
                                </div>
                                
                                <div class="col bg-black mt-3 p-5 d-flex align-content-center align-items-center">
                                    <div class="d-flex flex-column">
                                        <h4><b>{{$t('0y59ui10')}}</b></h4>
                                        <p><span v-html="$t('pysytgvw')"></span></p>
                                    </div>
                                </div>
                         
                                <div class="shadow"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-4 my-5 backgroundMedia media mt-5">
                <div class="row justify-content-center">
                    <div class="col-11 py-5 px-0">
                        <div class="row serviceItem">
                            <div class="col-12 mt-5">
                                <h1 id="mediaSection" class="text-white">{{$t('o1unthlq')}}</h1>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-5">
                                <div data-aos="fade-right" data-aos-duration="1500" data-aos-delay="250" class="imgWrapper">
                                    <img src="../assets/img/peter-stumpf-Recadre.jpg" alt="studiopicture" />
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-5" data-aos="fade-left" data-aos-duration="1500" data-aos-delay="250">
                                <div class="row h-100 flex-column">
                                    <div class="col">
                                        <div class="row g-4 h-100">
                                            <div class="col-sm-12 col-md-12 col-lg-5 col-xl-6"> 
                                                <div class="black p-5 h-100">
                                                    <h4 class="m-0 mb-2"><b>{{ $t('ankejoch') }}</b></h4>
                                                    <p>
                                                    {{$t('t2856gbq')}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5 col-xl-6"> 
                                                <div class="black p-5 h-100">
                                                    <h4 class="m-0 mb-2"><b>{{$t('tzo86285')}}</b></h4>
                                                    <p>
                                                    {{$t('w3i64kv9')}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5 col-xl-6"> 
                                                <div class="black p-5 h-100">
                                                    <h4 class="m-0 mb-2"><b>{{$t('wt3bvj4k')}}</b></h4>
                                                    <p>
                                                    {{$t('rt1pdt88')}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5 col-xl-6"> 
                                                <div class="black p-5 h-100">
                                                    <h4 class="m-0 mb-2"><b>{{$t('mlrqqlgq')}}</b></h4>
                                                    <p>
                                                    {{$t('s0iwnmlt')}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-auto">
                                        <p>
                                            {{$t('071tvhwe')}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-4 m-0 sousTitrage line">
                <div class="background second"></div>
                <div class="row justify-content-center pt-5">
                    <div class="col-11 p-0">
                        <div class="row">
                            <div class="col-11">
                                <h1 id="titrageSection">{{$t('h11hetwf')}}</h1>
                            </div>
                        </div>

                        <p>{{ $t('40vvn5h1') }}</p>
                        <div class="row serviceItem flex-sm-row-reverse g-5 left mb-5">
                            <div class="col-sm-12 col-md-12 col-lg col-xl shadowWrapper">
                                <div data-aos="fade-left" data-aos-duration="1500" data-aos-delay="250" class="imgWrapper">
                                    <img src="../assets/img/front/sous-titrage.webp" alt="studiopicture" />
                                </div>
                                <div class="shadow"></div>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 shadowWrapper" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="250">
                                <div class="row flex-column h-100">
                                    <div class="col">
                                        <div class="row h-100 g-3">
                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('pao4p44x') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('4x723gj6') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('o4wwj3os') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('nr5phaar') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('h20tr2fv') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('gz2u21ke') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('ajvqruw8') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('mwggtk6t') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('a3jkyrcj') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('wnoclonb') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 hideSpain">
                                            <div class="black p-4"><p class="m-0">{{ $t('dlvzwm6j') }}</p></div>
                                        </div>

                                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <div class="black p-4"><p class="m-0">{{ $t('ds5lthh5') }}</p></div>
                                        </div>
                                        </div>  
                                    </div>

                                    <div class="col-auto pt-3">
                                        <p><span v-html="$t('iycmxxrz')"></span></p>
                                    </div>
                                </div>
                                <div class="shadow"></div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>

            <div class="mt-5 technologie my-5 ">
                <div class="px-4 row justify-content-center">
                    <div class="col-11 p-0">
                        <div class="row mt-5">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <h1 id="technologieSection">{{$t('biekwokz')}}</h1>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" data-aos="fade-left" data-aos-duration="1500" data-aos-delay="250">
                            <p>
                                {{$t('zh75cepe')}}
                                <br><br>
                                {{$t('s9a8x2gw')}}
                                <br><br>
                            </p>

                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 galaxieWrapper"> 
                    <div class="gradient gradientTop"></div>
                    <div id="galaxy-wrapper" class="galaxy-wrapper2">
                        <div class="galaxy-image-wrapper">
                            
                            <div class="galaxy-info galaxy-info-bottom">
                                <svg  viewBox="0 0 100 100" preserveAspectRatio="none"  class="arrow arrow-bottom">
                                    <polygon points="45,0 0,100 100,100 55,0" fill="#000000e1" />
                                </svg>
                                <div class="galaxy-info-content">
                                    <div class="galaxy-info-title-and-image">
                                        <img src="img/axis_symbol.svg" class="galaxy-info-title-image" />
                                        <span class="galaxy-info-title">Title</span></div>
                                        <div class="galaxy-info-description"> Description</div>
                                </div>
                            </div>
                            
                            <div class="galaxy-info galaxy-info-top">
                                <div class="galaxy-info-content">
                                    <div class="galaxy-info-title-and-image">
                                        <img src="img/axis_symbol.svg" class="galaxy-info-title-image" />
                                        <span class="galaxy-info-title">Title</span></div>
                                        <div class="galaxy-info-description"> Description</div>
                                </div>
                                <svg  viewBox="0 0 100 100" preserveAspectRatio="none"  class="arrow arrow-top">
                                    <polygon points="45,100 0,0 100,0 55,100" fill="#000000e1" />
                                </svg>
                            </div>

                            <div class="galaxy-info galaxy-info-left d-flex">
                                <div class="galaxy-info-content col">
                                    <div class="galaxy-info-title-and-image">
                                        <img src="img/axis_symbol.svg" class="galaxy-info-title-image" />
                                        <span class="galaxy-info-title">Title</span>
                                    </div>
                                    <div class="galaxy-info-description"> Description</div>
                                </div>
                                <svg viewBox="0 0 100 100" preserveAspectRatio="none"  class="arrow arrow-left">
                                    <polygon points="0,100 100,55 100,45 0,0" fill="#000000e1" />
                                </svg>
                            </div>

                            <div class="galaxy-info galaxy-info-right d-flex">
                                <svg viewBox="0 0 100 100" preserveAspectRatio="none"  class="arrow arrow-right">
                                    <polygon points="100,100 0,55 0,45 100,0" fill="#000000e1" />
                                </svg>
                                <div class="galaxy-info-content col">
                                    <div class="galaxy-info-title-and-image">
                                        <img src="img/axis_symbol.svg" class="galaxy-info-title-image" />
                                        <span class="galaxy-info-title">Title</span>
                                    </div>
                                    <div class="galaxy-info-description"> Description</div>
                                </div>
                            </div>

                            <div class="galaxy-info galaxy-info-left-top">
                                <div class="galaxy-info-content">
                                    <div class="galaxy-info-title-and-image">
                                        <img src="img/axis_symbol.svg" class="galaxy-info-title-image" />
                                        <span class="galaxy-info-title">Title</span></div>
                                    <div class="galaxy-info-description"> Description</div>
                                </div>
                                <svg  viewBox="0 0 100 100" preserveAspectRatio="none"  class="arrow arrow-bottom">
                                    <polygon points="100,100 0,0 100,0 100,100" fill=" 000000e1" />
                                </svg>
                            </div>

                            <div class="galaxy-info galaxy-info-left-bottom">
                                <svg  viewBox="0 0 100 100" preserveAspectRatio="none"  class="arrow arrow-bottom">
                                    <polygon points="100,0 0,100 100,100 100,0" fill=" 000000e1" />
                                </svg>
                                <div class="galaxy-info-content">
                                    <div class="galaxy-info-title-and-image">
                                        <img src="img/axis_symbol.svg" class="galaxy-info-title-image" />
                                        <span class="galaxy-info-title">Title</span></div><div class="galaxy-info-description"> Description</div>
                                </div>
                            </div>

                            <div class="galaxy-info galaxy-info-right-top">
                                <div class="galaxy-info-content">
                                    <div class="galaxy-info-title-and-image">
                                        <img src="img/axis_symbol.svg" class="galaxy-info-title-image" />
                                        <span class="galaxy-info-title">Title</span></div><div class="galaxy-info-description"> Description</div>
                                </div>
                                <svg  viewBox="0 0 100 100" preserveAspectRatio="none"  class="arrow arrow-bottom">
                                    <polygon points="0,100 0,0 100,0 0,100" fill=" 000000e1" />
                                </svg>
                            </div>

                            <div class="galaxy-info galaxy-info-right-bottom">
                                <svg  viewBox="0 0 100 100" preserveAspectRatio="none"  class="arrow arrow-bottom">
                                    <polygon points="0,0 100,100 0,100 0,0" fill=" 000000e1" />
                                </svg>
                                <div class="galaxy-info-content">
                                    <div class="galaxy-info-title-and-image">
                                        <img src="img/axis_symbol.svg" class="galaxy-info-title-image" />
                                        <span class="galaxy-info-title">Title</span></div><div class="galaxy-info-description"> Description</div>
                                </div>
                            </div>

                            <img src="../assets/img/img-planet/map-noplanet.jpg" class="galaxy-map" data-width="3711" data-height="2137" v-on:click="this.ZoomIn(null)"/>
                            <img src="../assets/img/img-planet/axis_symbol.svg" class="galaxy-icon axis" data-width="144" data-height="144" data-centerx="3174" data-centery="516" data-title="Axis" :data-description="$t('u9hjyqmd')" data-infoplacement="left" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/saloon_symbol.svg" class="galaxy-icon saloon" data-width="128" data-height="128" data-centerx="2567" data-centery="768" data-title="Saloon" :data-description="$t('4eb484n1')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/comet_symbol.svg" class="galaxy-icon comet" data-width="123" data-height="123" data-centerx="2881" data-centery="896" data-title="Comet" :data-description="$t('3enjgqwg')" data-infoplacement="left" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/map_symbol.svg" class="galaxy-icon mapapp" data-width="159" data-height="159" data-centerx="3547" data-centery="967" data-title="MAP" :data-description="$t('friw4tot')" data-infoplacement="left" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/erytmo_studio_symbol.svg" class="galaxy-icon erytmo" data-width="155" data-height="155" data-centerx="3167" data-centery="1321" data-title="Erytmo Studio" :data-description="$t('lq3o1538')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/metrics_symbol.svg" class="galaxy-icon metrics" data-width="104" data-height="104" data-centerx="2834" data-centery="1084" data-title="Metrics" :data-description="$t('bq455rp2')" data-infoplacement="bottom" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/inventory_symbol.svg" class="galaxy-icon inventory" data-width="125" data-height="125" data-centerx="2415" data-centery="1102" data-title="Inventory" :data-description="$t('mozysnme')" data-infoplacement="bottom" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/e_dub_symbol.svg" class="galaxy-icon e-dub" data-width="95" data-height="95" data-centerx="2343" data-centery="914" data-title="E-Dub" :data-description="$t('1ghfrfch')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/e_sub_symbol.svg" class="galaxy-icon e-sub" data-width="120" data-height="120" data-centerx="2090" data-centery="1264" data-title="E-Sub" :data-description="$t('csvma3i6')" data-infoplacement="bottom" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/screener_symbol.svg" class="galaxy-icon screener" data-width="129" data-height="129" data-centerx="1968" data-centery="750" data-title="Screener" :data-description="$t('3ae5y535')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/lantern_symbol.svg" class="galaxy-icon lantern" data-width="179" data-height="179" data-centerx="1872" data-centery="1069" data-title="Lantern" :data-description="$t('4rnxe9r8')" data-infoplacement="bottom" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/magneto_symbol.svg" class="galaxy-icon magneto" data-width="95" data-height="95" data-centerx="1599" data-centery="1062" data-title="Magneto" :data-description="$t('zkbbw4lg')" data-infoplacement="left" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/forge_symbol.svg" class="galaxy-icon forge" data-width="104" data-height="104" data-centerx="1693" data-centery="770" data-title="Forge" :data-description="$t('y4skekz6')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/galaxy_symbol.svg" class="galaxy-icon galaxy" data-width="126" data-height="126" data-centerx="1395" data-centery="1379" data-title="Galaxy" :data-description="$t('olwezvcj')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/voicematch_symbol.svg" class="galaxy-icon voicematch" data-width="118" data-height="118" data-centerx="1213" data-centery="833" data-title="VoiceMatch" :data-description="$t('88ros6d8')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)">
                            <img src="../assets/img/img-planet/planet_symbol.svg" class="galaxy-icon planet" data-width="114" data-height="114" data-centerx="878" data-centery="905" data-title="Planet" :data-description="$t('ne3hkcth')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/erytmo_stream_symbol.svg" class="galaxy-icon erytmo" data-width="132" data-height="132" data-centerx="784" data-centery="1077" data-title="Erytmo Stream" :data-description="$t('4djfn2gw')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/voicetest_symbol.svg" class="galaxy-icon voicetest" data-width="126" data-height="126" data-centerx="370" data-centery="1280" data-title="VoiceTest" :data-description="$t('o2a2dy1p')" data-infoplacement="right" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/erytmo_factory_symbol.svg" class="galaxy-icon erytmo" data-width="140" data-height="140" data-centerx="563" data-centery="1445" data-title="Erytmo Factory" :data-description="$t('brf15hy8')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/vega_symbol.svg" class="galaxy-icon vega" data-width="172" data-height="172" data-centerx="874" data-centery="1780" data-title="Vega" :data-description="$t('ku98d5o9')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/synergie_symbol.svg" class="galaxy-icon synergie" data-width="140" data-height="140" data-centerx="3342" data-centery="773" data-title="Synergie" :data-description="$t('wind2k2k')" data-infoplacement="left" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                            <img src="../assets/img/img-planet/ktools_symbol.svg" class="galaxy-icon k-tools" data-width="139" data-height="139" data-centerx="1732" data-centery="1598" data-title="K-Tools" :data-description="$t('ds9bew75')" data-infoplacement="top" v-on:mouseenter="this.ZoomOut($event.target)" v-on:click="this.ZoomOut($event.target)" v-on:mouseleave="this.ZoomIn($event.target)"/>
                        </div>
                    </div>                 
                    <div class="gradient gradientBottom"></div>
                </div>

                <div class="px-4 mb-5">
                    <div class="row justify-content-center mb-5">
                        <div class="col-11 p-0">
                            <div class="row my-5 g-3" >
                                <p>{{$t('q9hngs66')}}</p>
                                <div class="col-sm-12 col-md-12 col-lg col-xl"  data-aos="fade-right" data-aos-duration="1500" data-aos-delay="250">
                                    <div class="black p-3">
                                        <h4>{{$t('peaan5wm')}}</h4>
                                        <p class="m-0"><span v-html="$t('zhq0oaxl')"></span></p>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg col-xl"  data-aos="fade-right" data-aos-duration="1500" data-aos-delay="250">
                                    <div class="black p-3">
                                        <h4>{{$t('h11hetwf')}}</h4>
                                        <p class="m-0">{{$t('xlbvu3ni')}}</p>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg col-xl"  data-aos="fade-left" data-aos-duration="1500" data-aos-delay="250">
                                    <div class="black p-3">
                                        <h4>{{$t('o1unthlq')}}</h4>
                                        <p class="m-0">{{$t('fbifx0dl')}}</p>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg col-xl"  data-aos="fade-left" data-aos-duration="1500" data-aos-delay="250">
                                    <div class="black p-3">
                                        <h4>{{$t('gxg835yh')}}</h4>
                                        <p class="m-0">{{$t('q7knv3gz')}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="background second third"></div>
            </div>

            <div class="graphicOne shiftOne"></div>
        </main>
    </div>
</template>

<script>
export default {
    name: "ServicesPage",
    data() {
        return {
            iconZoomFactor: 1.25,
            lastIcon: null
        }
    },
    mounted() {
        var map = document.getElementsByClassName("galaxy-map")[0];
        var mapWidth = +map.dataset.width;
        var mapHeight = +map.dataset.height;
        var icons = document.getElementsByClassName("galaxy-icon");

        for(var i = 0; i < icons.length; i++)
        {
            var iconWidth = +icons[i].dataset.width;
            var iconHeight = +icons[i].dataset.height;
            var iconCenterX = +icons[i].dataset.centerx;
            var iconCenterY = +icons[i].dataset.centery;
            var title = icons[i].dataset.title;
            var description = icons[i].dataset.description;
            var infoplacement = icons[i].dataset.infoplacement;
            var transform = "";
            var isLeft = infoplacement.includes("left");
            var isRight = infoplacement.includes("right");
            var isTop = infoplacement.includes("top");
            var isBottom = infoplacement.includes("bottom");

            var infoPanelClassName = "bottom";
            
            if (isLeft) {
                if (isTop)
                {
                    infoPanelClassName = "left-top";
                    transform += " matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)";
                }
                else if (isBottom)
                {
                    infoPanelClassName = "left-bottom";
                    transform += " matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)";
                }
                else
                {
                    infoPanelClassName = "left";
                    transform += " matrix3d(1, 0, 0, 0.0008, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)";
                }
            }
            else if (isRight) {
                if (isTop)
                {
                    infoPanelClassName = "right-top";
                    transform += " matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)";
                }
                else if (isBottom)
                {
                    infoPanelClassName = "right-bottom";
                    transform += " matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)";
                }
                else
                {
                    infoPanelClassName = "right";
                    transform += " matrix3d(1, 0, 0, -0.0008, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)";
                }
            }
            else if (isTop)
            {
                infoPanelClassName = "top";
                transform += "matrix3d(1, 0, 0, 0, -0.05, 1, 0, 0.0005, 0, 0, 1, 0, 0, 0, 0, 1)";
            }
            else if (isBottom)
            {
                infoPanelClassName = "bottom";
                transform += "matrix3d(1, 0, 0, 0, 0.05, 1, 0, -0.0005, 0, 0, 1, 0, 0, 0, 0, 1)";
            }

            var infoPanelClone = document.getElementsByClassName("galaxy-info-" + infoPanelClassName)[0].cloneNode(true);

            if (isLeft)
            {
                infoPanelClone.style.setProperty("right", (100 - (100 * iconCenterX) / mapWidth) + "%");
            }
            else if (isRight)
            {
                infoPanelClone.style.setProperty("left", (100 * iconCenterX) / mapWidth + "%");
            }
            else {
                transform += " translateX(-50%)";
                infoPanelClone.style.setProperty("left", (100 * iconCenterX) / mapWidth + "%");
            }

            if (isTop)
            {
                infoPanelClone.style.setProperty("bottom", (100 - (100 * iconCenterY) / mapHeight) + "%");
            }
            else if (isBottom)
            {
                infoPanelClone.style.setProperty("top", (100 * iconCenterY) / mapHeight + "%");
            }
            else
            {
                transform += " translateY(-50%)";
                infoPanelClone.style.setProperty("top", (100 * iconCenterY) / mapHeight + "%");
            }

            infoPanelClone.transformPosition = transform;

            infoPanelClone.style.setProperty("transform", infoPanelClone.transformPosition + " scale(0.5)");
            infoPanelClone.style.setProperty("opacity", "0");
            infoPanelClone.addEventListener("transitionend", (event) => {
                if (event.target.style.opacity == "0")
                    event.target.style.setProperty("z-index", 2);
            });

            map.parentElement.appendChild(infoPanelClone);
            infoPanelClone.getElementsByClassName("galaxy-info-title")[0].innerHTML = " " + title;
            infoPanelClone.getElementsByClassName("galaxy-info-title-image")[0].src = icons[i].src;
            infoPanelClone.getElementsByClassName("galaxy-info-description")[0].innerHTML = description;
            
            icons[i].infoPanel = infoPanelClone;
            icons[i].style.setProperty("left", (100 * (iconCenterX - iconWidth / 2)) / mapWidth + "%");
            icons[i].style.setProperty("top", (100 * (iconCenterY - iconHeight / 2)) / mapHeight + "%");
            icons[i].style.setProperty("width", (100 * iconWidth) / mapWidth + "%");
            icons[i].style.setProperty("height", (100 * iconHeight) / mapHeight + "%");
            icons[i].style.setProperty("opacity", "1");
        }

        setTimeout(this.RandomMovements, 2000);
    },
    methods: {
        ZoomOut(icon)
        {
            if (this.lastIcon != null)
            {
                this.ZoomIn(null);
            }
            
            var map = document.getElementsByClassName("galaxy-map")[0];
            var icons = document.getElementsByClassName("galaxy-icon");
            var mapWidth = +map.dataset.width;
            var mapHeight = +map.dataset.height;
            
            for (var i = 0; i < icons.length; i ++)
            {
                var iconCenterX = +icons[i].dataset.centerx;
                var iconCenterY = +icons[i].dataset.centery;
                var iconWidth = +icons[i].dataset.width;
                var iconHeight = +icons[i].dataset.height;
                
                if (icons[i] == icon)
                {
                    icons[i].style.setProperty("left", (100 * (iconCenterX - iconWidth / 2)) / mapWidth + "%");
                    icons[i].style.setProperty("top", (100 * (iconCenterY - iconHeight / 2)) / mapHeight + "%");
                    icons[i].style.setProperty("width", (100 * iconWidth) / mapWidth + "%");
                    icons[i].style.setProperty("height", (100 * iconHeight) / mapHeight + "%");
                    icons[i].style.setProperty("transform", "scale(" + this.iconZoomFactor + ")");
                    icons[i].style.setProperty("opacity", "1");
                    icons[i].style.setProperty("z-index", "5");
                }
                else
                {
                    icons[i].style.setProperty("z-index", "3");
                }
            }
            
            icon.infoPanel.style.setProperty("transform", icon.infoPanel.transformPosition + " scale(1)" );
            icon.infoPanel.style.setProperty("opacity", "1");
            icon.infoPanel.style.setProperty("z-index", "4");
            this.lastIcon = icon;
        },
        ZoomIn(icon)
        {
            if (!icon && this.lastIcon)
                icon = this.lastIcon;
            
            if (icon)
            {
                icon.infoPanel.style.setProperty("transform", icon.infoPanel.transformPosition + " scale(0.5)");
                icon.infoPanel.style.setProperty("opacity", "0");
                icon.style.setProperty("transform", "scale(1)");
            }
            
            this.lastIcon = null;
        },
        RandomMovements()
        {
            if (document.body.contains(this.$el))
            {
                var icons = document.getElementsByClassName("galaxy-icon");
                var iconIndex = Math.floor(icons.length * Math.random());
                
                var size = 1.2 + 0.5 * Math.random();
                icons[iconIndex].style.setProperty("transform", "scale(" + size + ") rotateY(180deg)");
                setTimeout(() => {
                    if (icons[iconIndex])
                        icons[iconIndex].style.setProperty("transform", "scale(1) rotateY(0deg)");
                }, 2000);

                setTimeout(this.RandomMovements, 3000);
            }
        }
    }
}
</script>

<style>
    .sectionServices .backgroundMedia {
        /* background: radial-gradient(90.53% 83.35% at -10.46% -8.51%, #F5D3A4 0%, #B08C5C 100%); */
        background: radial-gradient(ellipse 90.53% 83.35% at 10% 10%, rgba(220, 175, 115, 0.71) 0%, rgb(132, 104, 66) 53%);
    }

    .sectionServices .line {
        background: radial-gradient(ellipse 100% 146% at 50% 122%, rgb(204 167 117 / 58%) 0%, rgb(0 0 0 / 0) 53%);
        border-image: linear-gradient(90deg,#00000000 9%, #CCA775 50%, #00000000 91%) 24;
        border-width: 0 0 1px 0;
        border-style: solid;
    }

    .sectionServices .black {
        background-color: rgba(0, 0, 0, 0.8);
    }

    .sectionServices .backgroundMedia .black {
        padding-top: 10vh !important;
    }


    .sectionServices .technologie .black {
        min-height: 225px;
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(165deg,#ffffff90,#000000e1 31%,#ffffff8f 160%) 24;
        padding-top: 40px !important;
    }

    .sectionServices .backgroundMedia .black {
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(165deg,#ffffff90,#000000e1 31%,#ffffff8f 160%) 24;
    }


    .sectionServices .sousTitrage .black {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 100%;
        min-height: 160px;
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(165deg,#ffffff90 0, #000000e1 31%, #ffffff8f 160%) 24;
    }

    .sectionServices .sousTitrage .background {
        background-position: 165% 45%;
        filter: opacity(.80);
        background-size: 130%;
    }
    
    .sectionServices .technologie .background {
        background-position: 26% 119%;
        background-size: 50%;
        background-image: unset;
        height: 30vh;
        background: radial-gradient(ellipse at 50% 100%, rgb(255 255 255) -4%, rgb(0 0 0) 67%);
    }

    .shadowWrapper {
        padding-bottom: 10vh;
        position: relative;
    }

    @media screen and (max-width: 1024px) {
        .shadowWrapper {
            padding-bottom: 3vh;
            position: relative;
        }
    }

    .shadowWrapper .shadow {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width:90%;
        height: 50px;
        background: radial-gradient(black, transparent);
        filter: blur(25px);
        margin: auto;
    }
    
    .galaxieWrapper {
        position: relative;
    }

    .gradient {
        position: absolute;
        z-index: 9999;
        height: 110px;
        width: 100%;
    }

    .gradientTop {
        top: 0;
        background: linear-gradient(180deg, rgba(204, 167, 117, 0) 33.33%, rgba(0, 0, 0, 0) 33.34%, #000000 90.57%);
        transform: matrix(1, 0, 0, -1, 0, 0);
    }

    .gradientBottom {
        bottom: 0;
        background: linear-gradient(180deg, rgba(204, 167, 117, 0) 5.94%, rgba(0, 0, 0, 0) 5.95%, #000000 83.17%);
        height: 60px;
    }

    

    .gold {
        background: radial-gradient(90.53% 83.35% at -10.46% -8.51%, #c2c2c2 0%, #1c1c1c 100%);
        color: #ffffff;
        height: 100%;
        min-height: 150px;
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(165deg,#ffffffe0 0, #000000e1 31%, #ffffffe1 160%) 24;
    }

    #servicesPage p {
        /* font-size: calc(0.52vw + 8px); */
    }

</style>