<template>
    <div id="referencesPage" class="singlePage">
        <img class="wave-background" src="../assets/img/background_lines_wave.svg">
        <main class="mb-5">
            <div class="background first"></div>
            <div class="container-fluid px-4 m-0">
              <div class="row justify-content-center">
                <div class="col-11 p-0">
                  <h1>{{$t('ryo0sy7j')}}</h1>
                </div>
              </div>
            </div>
            <div class="container-fluid">
                  <div class="row mb-3">
                    <carousel :settings="settings" :breakpoints="breakpoints" :wrapAround="true"  data-aos="fade-up" data-aos-duration="1500" class="mb-5 px-0" touch="true">
                        <Slide v-for="slide in 13" :key="slide">
                          <div class="carousel__item"><img class="poster-img" :src="getImage(slide)"></div>
                        </Slide>

                        <template #addons>
                        <navigation />
                        <!-- <pagination /> -->
                        </template>
                    </carousel>
                  </div>

                  <div class="row my-3">
                    <carousel :settings="settings" :breakpoints="breakpoints" :wrapAround="true" :autoplay="3000" data-aos="fade-up" data-aos-duration="1500" class="mb-5 px-0" touch="true">
                        <Slide v-for="slide in 13" :key="slide">
                          <div class="carousel__item"><img class="poster-img" :src="getImage(slide + 13 )"></div>
                        </Slide>

                        <template #addons>
                        <navigation />
                        <!-- <pagination /> -->
                        </template>
                    </carousel>
                  </div>
                  
                  <div class="row mb-5">
                    <carousel :settings="settings" :breakpoints="breakpoints" :wrapAround="true" :autoplay="3000" data-aos="fade-up" data-aos-duration="1500" class="mb-5 px-0" touch="true">
                        <Slide v-for="slide in 13" :key="slide">
                          <div class="carousel__item"><img class="poster-img" :src="getImage(slide + 26)"></div>
                        </Slide>

                        <template #addons>
                        <navigation />
                        <!-- <pagination /> -->
                        </template>
                    </carousel>
                  </div>
            </div>
            <div class="background"></div>
          </main>
          <img class="wave-background" src="../assets/img/background_lines_wave.svg">
    </div>
</template>

<script>
import '@/assets/css/references.css';

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
    name: "ReferencesPage",
    components: {
        Carousel,
        Slide,
        //Pagination,
        Navigation,
  },
    data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
        // 250px and up
        250: {
          itemsToShow: 2.5,
          snapAlign: 'center',
        },
        // 700px and up
        700: {
          itemsToShow: 4.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 6.5,
          snapAlign: 'start',
        },
        // 1960 and up
        1920: {
          itemsToShow: 8.5,
          snapAlign: 'start',
        },
      },
    }),
    mounted() {
    },
    methods: {
        getImage(id) { 
            var img = require('@/assets/img/posters/' + id + '.jpg');
            if (img) {
                return img;
            }
        }
    },
}
</script>


<style scoped>
  .carousel__slide {
    padding: 5px;
  }
  .carousel__slide > div , .carousel__slide img {
    height: 100%;
  }
  .carousel__viewport {
    perspective: 2000px;
  }

  .carousel__track {
    transform-style: preserve-3d;
  }

  .carousel__slide--sliding {
    transition: 0.5s;
  }

  .carousel__slide {
    opacity: 1;
  }

  .carousel__slide--prev {
    opacity: 1;

  }

  .carousel__slide--next {
    opacity: 1;

  }

  .carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1.0);
  }
</style>
