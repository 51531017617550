<template>
    <div id="aproposPage" class="singlePage">
        <img class="wave-background" src="../assets/img/background_lines_wave.svg">
        <main>
            <div class="background first"></div>

            <div class="px-4">
                <div class="row g-3 justify-content-center">
                    <div class="col-sm-12 col-md-11 col-lg-11 col-xl-11 col-xxl-11">
                        <div class="row g-3">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
                                <div class="text">
                                    <h1>{{ $t('nxj07zuh') }}</h1>
                                    <p><span v-html="$t('4sa6vlp7')"></span></p>
                                    <h3 style="color: var(--gold); font-weight: 600; font-size: 1.40em;">{{ $t('eoca8pim') }}</h3>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg col-xl col-xxl">
                                <div class="imgWrapper">
                                            <img src="../assets/img/portrets/alexandre-et-mathieu-resized.jpg" alt="portrait">
                                </div>
                                <div class="row flex-nowrap">
                                    
                                    <div class="col">
                                        <div class="owner pe-5" style="text-align: right;" >
                                            <h6 >Mathieu Taieb</h6>
                                            <p><small>{{ $t('dinrcspc') }}</small></p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="owner">
                                            <h6>Alexandre Taieb</h6>
                                            <p><small>{{ $t('of74q3tj') }}</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="py-5 my-4 px-4">
                <div class="row g-3 justify-content-center">
                    <div class="col-sm-12 col-md-11 col-lg-11 col-xl-11 col-xxl-11">
                        <div class="row counterContainer justify-content-col-center g-3">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                    <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="250">
                                        <div class="counterWrapper">+&nbsp;<number class="counter" :from="0" :to="parseInt(this.$t('b68a9s4s'))" :duration="5" :delay="2" easing="Power1.easeOut"/></div>
                                        <h3>{{$t('r2fr4mty')}}</h3> 
                                        <span v-html="$t('ilmh691m')"></span>
                                    </div>
                                </div>
                
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                    <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="350">
                                        <div class="counterWrapper">+&nbsp;<number class="counter" :from="0" :to="parseInt(this.$t('808ck5pn'))" :duration="5" :delay="2" easing="Power1.easeOut"/></div>

                                        <h3>{{$t('bolkqodn')}}</h3>
                                        <span>{{$t('x0ffc8it')}}</span>
                                    </div>
                                </div>
                
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                    <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="450">
                                        <div class="counterWrapper">+&nbsp;<number class="counter" :from="0" :to="parseInt(this.$t('fts49xs9'))" :duration="5" :delay="2" easing="Power1.easeOut"/></div>
                                        
                                        <h3>{{$t('yswlxrlg')}}</h3>
                                        <span v-html="$t('q4pvc2ng')"></span>
                                    </div>
                                </div>
                
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                    <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="550">
                                        <div class="counterWrapper">+&nbsp;<number class="counter" :from="0" :to="parseInt(this.$t('k8l0tx2o'))" :duration="5" :delay="2" easing="Power1.easeOut"/></div>

                                        <h3>{{$t('nawcg8n4')}}</h3>
                                        <span>{{$t('2er7mh9k')}}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="alternative2">
                <div class="heroSection two px-4 py-5">
                    <div class="row g-3 mt-5 justify-content-center">
                        <div class="col-sm-12 col-md-11 col-lg-11 col-xl-11 col-xxl-11 text-start text-sm-start text-md-start text-lg-center text-xl-center">        
                            <h2>{{$t('w25aqfpp')}}</h2>
                        </div>
                    </div>
                </div>
                
                <div class="container-fluid px-4 mb-5">
                    <div class="row pb-5 justify-content-center">
                        <div class="col-sm-12 col-md-11 col-lg-10 col-xl-10">
                            <div class="row content partners g-3 gy-5 justify-content-center">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <div class="cardPartner" data-aos="fade-down" data-aos-duration="1500" data-aos-delay="100">
                                            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-speed="4000"  data-tilt-reset="true">
                                                <img src="../assets/img/partners/apple.svg" alt="">
                                            </div>
                                        </div>
                                    </div>  

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <div class="cardPartner" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100" >
                                            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-speed="4000"  data-tilt-reset="true">
                                            <img src="../assets/img/partners/nbcuniversal.svg" alt="">
                                            </div>
                                        </div> 
                                    </div>                                   

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <div class="cardPartner" data-aos="fade-down" data-aos-duration="1500" data-aos-delay="100">
                                            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-speed="4000"  data-tilt-reset="true">
                                            <img src="../assets/img/partners/amazon.svg" alt="">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <div class="cardPartner" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="250" >
                                            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-speed="4000"  data-tilt-reset="true">
                                            <img src="../assets/img/partners/netflix.svg" alt=""></div>
                                        </div>
                                    </div>                                     

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <div class="cardPartner" data-aos="fade-down" data-aos-duration="1500" data-aos-delay="250" >
                                            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-speed="4000"  data-tilt-reset="true">
                                            <img src="../assets/img/partners/disney.svg" alt=""></div>
                                        </div>
                                    </div>

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <div class="cardPartner" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="400" >
                                            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-speed="4000"  data-tilt-reset="true">
                                                <img src="../assets/img/partners/sonypictures.svg" alt="">
                                            </div>
                                        </div>
                                    </div>                                    

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <div class="cardPartner" data-aos="fade-down" data-aos-duration="1500" data-aos-delay="400" >
                                            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-speed="4000"  data-tilt-reset="true">
                                            <img src="../assets/img/metropolitan.png" alt=""></div>
                                        </div>
                                    </div>

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <div class="cardPartner" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500" >
                                            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-speed="4000"  data-tilt-reset="true">
                                                <img src="../assets/img/partners/hbo.svg" alt="">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <div class="cardPartner" data-aos="fade-down" data-aos-duration="1500" data-aos-delay="500" >
                                            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-speed="4000"  data-tilt-reset="true">
                                            <img src="../assets/img/partners/paramount.svg" alt=""></div>
                                        </div>
                                    </div>

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <div class="cardPartner" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500" >
                                            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-speed="4000"  data-tilt-reset="true">
                                                <img src="../assets/img/partners/wb.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="background"></div>
        </main>

        <img class="wave-background" src="../assets/img/background_lines_wave.svg">
    </div>
</template>

<script>
export default {
    name: "AboutPage",
    data() {
        return {
        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>