import axios from 'axios';
import config from './config';
import configStatic from './configStatic';
import Router from './../router/index';

const $axios = axios.create({
  baseURL: config.RESTLER_BACKEND_URL,
  withCredentials: true,
  headers: {
    app_code: configStatic.APP_CODE,
  },
});

$axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

$axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error) {
      if (error.response && error.response.status == 401) {
        Router.push({ path: '/login' });
      } else if (error.response && error.response.status == 403) {
        Router.push({ path: '/' });
      }

      return Promise.reject(error.response ? error.response.data : null);
    }
  }
);

$axios.setHeader = function (header, value) {
  $axios.defaults.headers.common[header] = value;
};

$axios.removeHeader = function (header) {
  delete $axios.defaults.headers.common[header];
};

export default $axios;
