<template>
    <div id="facilitiesPage" class="singlePage">
        <img class="wave-background" src="../assets/img/background_lines_wave.svg">
        <main>
            <div class="background first"></div>
            <div class="container-fluid px-4 m-0">
              <div class="row justify-content-center">
                <div class="col-11 p-0">
                    <h1>{{$t('tt6ovpvt')}}</h1>
                    <p>{{$t('7n2bls9k')}}</p>
                </div>
              </div>
            </div>

            <div class="container-fluid sectionFacilities px-4 pb-2 m-0">
                <div class="row justify-content-center my-5">
                    <div class="col-sm-12 col-md-11 content">
                        <div class="row flex-column flex-column-reverse align-items-center g-3 my-5">
                            <div class="col-sm-12 col-md-8" data-aos="fade-left"  data-aos-duration="3000" data-aos-delay="200">
                                <div id="carouselFacilities1" class="carousel slide mb-5" data-bs-interval="300" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active" data-bs-interval="5000">
                                            <div id='new-image-swipe'>
                                                <a
                                                v-for="(image, key) in images1"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                                <img src="../assets/img/img/studios/enregistrement2.jpg" class="d-block" alt="...">
                                                </a>
                                            </div>
                                        </div>

                                        <div class="carousel-item" data-bs-interval="5000">
                                            <div id='new-image-swipe'>
                                                <a
                                                v-for="(image, key) in images1"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                                <img src="../assets/img/img/studios/enregistrement4.jpg" class="d-block" alt="...">
                                                </a>
                                            </div>
                                        </div>

                                        <div class="carousel-item" data-bs-interval="5000">
                                            <div id='new-image-swipe'>
                                                <a
                                                v-for="(image, key) in images1"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                                <img src="../assets/img/img/studios/enregistrement5.jpg" class="d-block" alt="...">
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselFacilities1" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselFacilities1" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselFacilities1" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>

                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselFacilities1" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselFacilities1" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12" data-aos="fade-right"  data-aos-duration="3000" data-aos-delay="200">
                                <div class="description pt-5">
                                    <h3>{{ $t('grt2lols') }}</h3>
                                    <p>{{ $t('kiwzvg9t') }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row flex-column flex-column-reverse align-items-center g-3 my-5">
                            <div class="col-sm-12 col-md-8" data-aos="fade-left" data-aos-duration="3000">
                                <div id="carouselFacilities2" class="carousel slide mb-5" data-bs-interval="1200" data-aos-delay="200" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div id='new-image-swipe'>
                                                <a
                                                v-for="(image, key) in images2"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                                    <img src="../assets/img/img/studios/mixage3.jpg" class="d-block" alt="...">
                                                </a>
                                            </div>
                                        </div>

                                        <div class="carousel-item">
                                            <div id='new-image-swipe'>
                                                <a
                                                v-for="(image, key) in images2"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                                <img src="../assets/img/img/studios/mixage4.jpg" class="d-block" alt="...">
                                                </a>
                                            </div>
                                        </div>

                                        <div class="carousel-item">
                                            <div id='new-image-swipe'>
                                                <a
                                                v-for="(image, key) in images2"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                                <img src="../assets/img/img/studios/mixage5.png" class="d-block" alt="...">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                             
                                    <div class="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselFacilities2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselFacilities2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselFacilities2" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>

                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselFacilities2" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselFacilities2" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12 " data-aos="fade-right" data-aos-duration="3000" data-aos-delay="200">
                                <div class="description pt-5">
                                    <h3>{{$t('x2twqfti')}}</h3>
                                    <p>{{ $t('3x1daf8t') }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row flex-column flex-column-reverse align-items-center g-3 my-5">
                            <div class="col-sm-12 col-md-8" data-aos="fade-left" data-aos-duration="3000" >
                                <div id="carouselFacilities3" class="carousel slide mb-5" data-bs-interval="1200" data-aos-delay="200" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active" >
                                            <div id='new-image-swipe'>
                                                <a
                                                v-for="(image, key) in images3"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                                <img src="../assets/img/img/studios/alex_en_monttage.jpg" class="d-block" alt="...">
                                                </a>
                                            </div>
                                        </div>

                                        <div class="carousel-item " >
                                            <div id='new-image-swipe'>
                                                <a
                                                v-for="(image, key) in images3"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                                <img src="../assets/img/img/studios/montage1.jpg" class="d-block" alt="...">
                                                </a>
                                            </div>
                                        </div>

                                        <div class="carousel-item">
                                                    <div id='new-image-swipe'>
                                                <a
                                                v-for="(image, key) in images3"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                                <img src="../assets/img/img/studios/montage4.jpg" class="d-block" alt="...">
                                                </a>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselFacilities3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselFacilities3" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselFacilities3" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselFacilities3" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselFacilities3" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 " data-aos="fade-right" data-aos-duration="3000" data-aos-delay="200">
                                <div class="description pt-5">
                                    <h3>{{$t('uap868ib')}}</h3>
                                    <p>{{ $t('7qo75011') }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row flex-column flex-column-reverse align-items-center my-5">
                            <div class="col-sm-12 col-md-8" data-aos="fade-left"  data-aos-duration="3000" data-aos-delay="200" >
                                <div id="carouselFacilities4" class="carousel slide mb-5" data-bs-interval="3500" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active" >
                                            <div id='new-image-swipe'>
                                            <a
                                                v-for="(image, key) in images4"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                            <img src="../assets/img/img/studios/musique1.jpg" class="d-block" alt="...">
                                            </a>
                                            </div>
                                        </div>

                                        <div class="carousel-item">
                                            <div id='new-image-swipe'>
                                            <a
                                                v-for="(image, key) in images4"
                                                :key="key"
                                                :href="image.src"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-pswp-width="2500" 
                                                data-pswp-height="1669" 
                                                >
                                            <img src="../assets/img/img/studios/musique2.jpg" class="d-block" alt="...">
                                            </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselFacilities4" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselFacilities4" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    </div>

                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselFacilities4" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselFacilities4" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>

                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12" data-aos="fade-right"  data-aos-duration="3000" data-aos-delay="200">
                                <div class="description pt-5">
                                    <h3>{{$t('aopi470s')}}</h3>
                                    <p>{{ $t('fjaq547q') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="background"></div>
            <img class="wave-background" style="top:unset;bottom:20vh" src="../assets/img/background_lines_wave.svg">
        </main>
    </div>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default {
    name: "StudiosPage",
    data() {
        return {
            images1: [
                { src: require("@/assets/img/img/studios/mixage3.jpg") },
                { src: require("@/assets/img/img/studios/mixage4.jpg") },
                { src: require("@/assets/img/img/studios/mixage5.png") },
            ],
            images2: [
                { src: require("@/assets/img/img/studios/alex_en_monttage.jpg") },
                { src: require("@/assets/img/img/studios/montage1.jpg") },
                { src: require("@/assets/img/img/studios/montage4.jpg") },
            ],
            images3: [
                { src: require("@/assets/img/img/studios/alex_en_monttage.jpg"), thumbnail: require("@/assets/img/img/studios/alex_en_monttage.jpg") },
                { src: require("@/assets/img/img/studios/montage1.jpg"), thumbnail: require("@/assets/img/img/studios/montage1.jpg") },
                { src: require("@/assets/img/img/studios/montage4.jpg"), thumbnail: require("@/assets/img/img/studios/montage4.jpg") },
            ],
            images4: [
                { src: require("@/assets/img/img/studios/musique1.jpg"), thumbnail: require("@/assets/img/img/studios/musique1.jpg") },
                { src: require("@/assets/img/img/studios/musique2.jpg"), thumbnail: require("@/assets/img/img/studios/musique2.jpg") },
            ]
        }
    },
    mounted() {
        if (!this.lightbox) {
            this.lightbox = new PhotoSwipeLightbox({
                gallery: '#new-image-swipe',
                children: 'a',
                pswpModule: () => import('photoswipe'),
                initialZoomLevel: 'fit'
            });
            this.lightbox.init();
        }
    },
    unmounted(){
        if (this.lightbox) {
            this.lightbox.destroy();
            this.lightbox = null;
        }
    },
    methods: {

    }
}
</script>

<style>
    #facilitiesPage .sectionFacilities .description h3 {
        color: var(--gold);
        margin-bottom: 2vh;
    }
    #facilitiesPage .sectionFacilities .content > div {
        background: radial-gradient(ellipse 100% 146% at 50% 122%, rgb(204 167 117 / 58%) 0%, rgb(0 0 0 / 0) 53%);
        border-image: linear-gradient(90deg,#00000000 9%, #CCA775 50%, #00000000 91%) 24;
        border-width: 0 0 1px 0;
        border-style: solid;
    }
    #facilitiesPage .sectionFacilities .content > div:last-child {
        background: none;
        border: unset
    }

</style>