import configStatic from './configStatic';

let i = 1;
const ACCESS_TEST = i++;

class Role {
  constructor() {
    const { BRANCHES, USER_ROLES } = configStatic;

    this.ACCESS_ALL = {
      [BRANCHES.FR_BRANCH]: {},
    };

    this.ACCESS_ALL[BRANCHES.FR_BRANCH][USER_ROLES.ADMIN] = [ACCESS_TEST];

    this.resetAccess();
  }

  resetAccess() {
    this.CAN_ACCESS = {};
    this.CAN_ACCESS.TEST = false;
  }

  initAccess(branch) {
    this.CAN_ACCESS.TEST = this.hasAccess(ACCESS_TEST, branch);
  }

  hasAccess(access, branch) {
    return branch && this.ACCESS_ALL[branch.id] && this.ACCESS_ALL[branch.id][branch.role] && this.ACCESS_ALL[branch.id][branch.role].indexOf(access) > -1;
  }
}

export default new Role();
