<template>
  <div id="termsRecruit" class="singlePage terms">
      <main>
        <div class="container-fluid px-4 m-0">
            <div class="row justify-content-center">
              <div class="col-11">
                <h2>{{ $t('287t1oa3') }}</h2>
                <span v-html="$t('j0irqwrm')"></span>
                <span style="display: none;">
                  <p>Vos données personnelles sont traitées par DUBBING BROTHERS afin de gérer votre candidature à un poste, organiser des éventuels entretiens et constituer une Cvthèque. <br />
                  Les bases légales de ces traitements sont les mesures précontractuelles et l’intérêt légitime de Dubbing Brothers de constituer une base de données de CV.
                  </p>

                  <p>Les données collectées seront communiquées aux seuls destinataires suivants : Département « RH » de Dubbing Brothers. <br />
                  Elles seront conservées pendant une durée de 2 ans après le dernier contact avec vous. <br />
                  Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données. <br />
                  Vous pouvez également exercer votre droit à la portabilité de vos données. <br />
                  </p>
                  <p>Pour exercer ces droits ou pour toute question sur le traitement de vos données vous pouvez contacter: </p>
                  <ul>
                    <li>par mail : data@dubbingbrothers.com (à confirmer)</li>
                    <li>par courrier : DUBBING BROTHERS 19 rue de la Montjoie - 93210 La Plaine Saint Denis</li>
                    <li>par téléphone : 01 41 62 74 00 </li>
                  </ul>
                  <p>Si vous estimez, après nous avoir contactés, que vos droits n’ont pas été respectés, vous pouvez adresser une réclamation à la CNIL.</p>
                </span>
            </div>  
         </div>
        </div>
      </main>
  </div>
</template>

<script>
export default {
  name: "TermsRecruit",
}
</script>
