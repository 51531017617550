<template>
  <div id="privacyPage" class="singlePage terms">
      <main>
        <div class="container-fluid px-4 m-0">
          <div class="row justify-content-center">
              <div class="col-sm-12 col-md-12 col-lg-11 col-xl-11">
                <h2 class="mb-5">{{ $t('v0kbpq0j') }}</h2>
                <span v-html="$t('hkd6dvwg')"></span>

                <!-- <div style="display: none;">
                    <p>Cette politique de confidentialité vous informe de la façon dont Dubbing Brothers traite vos données personnelles dans le cadre de son site internet. 
                    La politique de confidentialité détaille également les droits dont vous disposez sur ces données et la façon de les exercer.  </p>

                    <h3>Qui est le responsable de traitement ? </h3>
                    <p>Vous avez fourni à Dubbing Brothers (SASU, RCS de Bobigny 350436028, située au 19 rue de la Montjoie 93210 Saint-Denis) via ce site internet des informations qui permettent de vous identifier : ce sont des données personnelles.  
                    <br /><br />
                    La loi oblige la personne qui recueille des informations personnelles à informer les personnes concernées notamment sur les finalités et les durées de conservation des données.
                    <br /><br />
                    Ces obligations sont prévues par le Règlement Européen (UE) 2016/679 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel (« RGPD ») et la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés.                    
                    <br /><br />
                    Une donnée personnelle est une information qui permet de vous identifier directement ou indirectement.
                    <br /><br />
                    Exemples : Vos noms, prénoms, adresse email, adresse IP, numéro de téléphone
                    <br /><br />
                    Dubbing Brothers agit en tant que responsable des traitements de vos données personnelles. 
                    <br /><br />
                    Conformément à l’article 4 du RGPD, le responsable de traitement est l’entité qui, seul ou conjointement avec d'autres, détermine les finalités et les moyens du traitement. Conformément à l’article 5 du RGPD, nous sommes responsables des actions réalisées sur vos données en notre possession.                    <br /><br />
                    <br /><br />
                    A ce titre, nous sommes responsables en cas de suppression, fuite ou modification de vos données personnelles.
                    <br /><br />
                    </p>

                    <h3>Quelles catégories de données collectons-nous et pour quelles finalités ? </h3>
                    <p>Dubbing Brothers collecte les catégories de données suivantes : </p>

                    <div class="illustration row justify-content-center my-5">
                      <div class="col-sm-12 col-md-12 col-lg-5 col-xl-4 my-2">
                          <div class="d-flex align-items-center">
                            <i class="fa fa-address-book"></i>
                            <span class="ms-2">
                              <p class="m-0">Vos données d’identification et de contact</p>
                              <small>Exemple : nom, prénom et adresse email</small>
                            </span>
                          </div>
                      </div>

                      <div class="col-sm-12 col-md-12 col-lg-5 col-xl-4 my-2">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-image"></i>
                          <span class="ms-2">
                            <p class="m-0">Vos données de navigation et de suivi</p>
                            <small>Exemple : adresse IP, préférence de langue, localisation.</small>
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    <div class="illustration row justify-content-center my-5">
                      <div class="col-sm-12 col-md-12 col-lg-5 col-xl-4 my-2">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-graduation-cap"></i>
                          <span class="ms-2">
                            <p class="m-0">Vos données de formation et expériences professionnelles </p>
                            <small>Exemple : diplômes, stages, emplois</small>
                          </span>
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-12 col-lg-5 col-xl-4 my-2">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-basketball"></i>
                          <span class="ms-2">
                            <p class="m-0">Vos habitudes de vie</p>
                            <small>Exemple : loisirs, activité, vie associative</small>
                          </span>
                        </div>
                      </div>

                    </div>
                    <p>Nous ne pouvons pas collecter vos données sans finalité ou but. Voici la liste des finalités pour lesquelles nous utilisons vos données :</p>

                    <ol class="listIllustration">
                      <li>
                        <span class="d-flex align-items-center">1.Traiter vos demandes et réprondre à vos Questions </span>
                        <span>
                          <i class="fa fa-address-book fa-fw"></i>
                        </span>
                      </li>

                      <li>
                        <span class="d-flex align-items-center">2.Gérer votre candidature et le recrutement</span>
                        <span>
                          <i class="fa fa-address-book fa-fw"></i>
                          <i class="fa fa-graduation-cap fa-fw"></i>
                          <i class="fa fa-basketball fa-fw"></i>
                        </span>
                      </li>

                      <li>
                        <span class="d-flex align-items-center">3.Constituer une CVthèque</span>
                        <span>
                          <i class="fa fa-address-book fa-fw"></i>
                          <i class="fa fa-graduation-cap fa-fw"></i>
                          <i class="fa fa-basketball fa-fw"></i>
                        </span>
                      </li>

                      <li>
                        <span class="d-flex align-items-center">4.Obtenir des statistiques de mesure d'audience</span>
                        <span>
                          <i class="fa fa-image fa-fw"></i>
                        </span>
                        </li>
                    </ol>

                    <h3>Chacune de vos données en notre possession doit servir à une finalité.</h3>
                    <p style="font-style: italic;">Conformément à l’article 5 du RGPD, les données sont collectées pour des finalités déterminées, explicites et légitimes. En cas de réutilisation des données ultérieurement, nous vous en informerons</p>
                    <br /><br />
                    <p>Nous devons, en outre, justifier que chacune de ces finalités repose sur l’un des fondements légaux prévus par la loi. Les voici : </p>

                    <ol class="listIllustration">
                      <li>1.Traiter vos demandes et réprondre à vos Questions</li>
                    </ol>
                    <div class="illustration2">
                        <div class="d-flex align-items-center gap-2">
                          <i class="fa fa-handshake"></i>
                          <p class="m-0 pe-3">Consentement</p>
                        </div>
                    </div>
                    <p style="font-style: italic;">Nous recueillons votre consentement lorsque vous remplissez le formulaire de contact sur le site. </p>
                    <br /><br />

                    
                    <ol class="listIllustration">
                      <li>2.Gérer votre candidature et le recrutement</li>
                    </ol>
                    <div class="illustration2 ">
                        <div class="d-flex align-items-center gap-2">
                          <i class="fa fa-signature"></i>
                          <p class="m-0 pe-3">Execution du contrat</p>
                        </div>
                    </div>
                    <p style="font-style: italic;">Le traitement de vos données dans le cadre du process de recrutement 
                    Dubbing Brothers s’appuie sur les mesures précontractuelles précédant l’éventuelle signature d’un contrat. </p>
                    <br /><br />



                    <ol class="listIllustration">
                      <li>3.Constituer une CVthèque</li>
                    </ol>
                    <div class="illustration2 ">
                        <div class="d-flex align-items-center gap-2">
                          <i class="fa fa-refresh"></i>
                          <p class="m-0 pe-3">Intérêsts légitimes</p>
                        </div>
                    </div>
                    <p style="font-style: italic;">Dubbing Brothers a un intérêt à conserver vos données de recrutement puisque cela permet de vérifier si un candidat a déjà postulé ou de rappeler un candidat intéressant.  </p>
                    <br /><br />

                    <ol class="listIllustration">
                      <li>4.Obtenir des statistiques de mesure d'audience</li>
                    </ol>
                    <div class="illustration2">
                        <div class="d-flex align-items-center gap-2">
                          <i class="fa fa-handshake"></i>
                          <p class="m-0 pe-3">Consentement</p>
                        </div>
                    </div>
                    <p style="font-style: italic;">Nous recueillons votre consentement via la bannière cookies.</p>
                    <br /><br /><br /><br />



                    <h3>Quels sont vos droits sur les données personnelles que nous collectons ?</h3>
                    <p>La loi et le RGPD vous garantissent 7 droits sur les données que nous collectons. Les voici : </p>
                    <div class="illustration">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">                      
                          <div class="d-flex">
                          <i class="fa fa-search  "></i>
                          <p class="ps-3">
                            <b>Droit d’accès</b>
                            vous permet d’obtenir une copie de vos données personnelles et des informations concernant leur traitement
                          </p>
                          </div>
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">                    
                          <div class="d-flex">
                          <i class="fa fa-circle-xmark  "></i>
                          <p class="ps-3">
                            <b>Droit d’opposition</b>
                            vous permet de retirer votre consentement et/ou d’interrompre le traitement de vos données dans certaines circonstances
                          </p>
                          </div>
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">                    
                          <div class="d-flex">
                          <i class="fa fa-eraser  "></i>
                          <p class="ps-3">
                            <b>Droit à l’effacement</b>
                            vous permet de demander la suppression de vos données dans certaines circonstances
                          </p>
                          </div>
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">                    
                          <div class="d-flex">
                          <i class="fa fa-hand  "></i>
                          <p class="ps-3">
                            <b>Droit à la limitation </b>
                            vous permet de demander à restreindre les données qui sont traitées dans certaines circonstances
                          </p>
                          </div>
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">                    
                          <div class="d-flex">
                          <i class="fa fa-pencil  "></i>
                          <p class="ps-3">
                            <b>Droit à la rectification </b>
                            vous permet de modifier vos données inexactes
                          </p>
                          </div>
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">                    
                          <div class="d-flex">
                          <i class="fa fa-arrow-up-right-from-square  "></i>
                          <p class="ps-3">
                            <b>Droit à la portabilité </b>
                            vous permet de recevoir les données que vous avez fournies afin de les transmettre à une autre entité
                          </p>
                          </div>
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">                    
                          <div class="d-flex">
                          <i class="fa fa-cross  "></i>
                          <p class="ps-3">
                            <b>Directives post mortem</b>
                            vous permet de décider de la manière dont vos données seront conservées, partagées ou supprimées après votre décès
                          </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br /><br /><br /><br />

                    <div class="mb-5">
                      <h3 class="mb-3">Ces droits s’appliquent différemment selon la base légale sur laquelle nous fondons nos finalités.</h3>
                      <div class="row height">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4 my-3">
                          <ol class="listIllustration">
                            <li>1.Traiter vos demandes et réprondre à vos Questions<br/>
                              et<br/>
                            4.Obtenir des statistiques de mesure d'audience</li>
                          </ol>
                          <p class="m-0">Vos droits : </p>
                          <div class="illustration row g-2">
                            <div class="col">
                              <i class="fa fa-search  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-circle-xmark  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-eraser  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-hand  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-arrow-up-right-from-square"></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-cross  "></i>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4 my-3">
                          <ol class="listIllustration">
                            <li>2.Gérer votre candidature et le recrutement</li>
                          </ol>
                          <p class="m-0">Vos droits : </p>
                          <div class="illustration row g-2">
                            <div class="col">
                              <i class="fa fa-search  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-circle-xmark  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-eraser  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-hand  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-arrow-up-right-from-square  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-cross  "></i>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4 my-3">
                          <ol class="listIllustration">
                            <li>3.Suivre l'utilisation du site pour l'améliorer et obtenir des statistiques de mesure d'audience.</li>
                          </ol>
                          <p class="m-0">Vos droits : </p>
                          <div class="illustration row g-2">
                            <div class="col">
                              <i class="fa fa-search  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-circle-xmark  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-eraser  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-hand  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-pencil  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-arrow-up-right-from-square  "></i>
                            </div>

                            <div class="col">
                              <i class="fa fa-cross  "></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="my-3" style="font-style: italic;">Conformément aux articles 15 à 21 du RGDP, vous pouvez nous contacter pour 
                        exercer certains droits sur les données en notre possession. À cette occasion, nous pouvons exiger des informations complémentaires. 
                        <br/>
                        Par la suite, vous pouvez également saisir la CNIL pour lui adresser une réclamation si vous estimez que nous n’avons pas respecté vos droits ou la réglementation relative à la protection des données.
                      </p>                
                    </div>


                    <p>Pour exercer ces droits (et de manière générale pour toute demande, vous pouvez nous contacter) aux coordonnées :
                      <ul>
                        <li>Par courrier postal à : 19 rue de la Montjoie 93210 Saint-Denis </li>
                        <li>Par email à : contact@dubbing-brothers.com </li>
                      </ul>
                    </p>
                    <br /><br /><br /><br />

                    <h3>Pendant quelle durée conservons-nous vos données personnelles ? </h3>
                    <p>Nous conservons vos données pendant une certaine durée. <br />
                      Généralement, les données traitées dans le cadre du formulaire de contact sont conservées pendant une durée de 3 ans à compter du dernier échange avec vous.  
                      <br/>
                      Concernant les données de recrutement (gestion et CVthèque), elles sont conservées pendant une durée de 2 ans après le dernier contact avec vous. <br />
                      Concernant les données collectées par traceurs, vous pouvez consulter la <a class="cookie-policy-link" href='/#/cookie-terms'>la politique cookies</a>.</p>
                    <br /><br /><br /><br />


                    <h3>Certains tiers ou prestataires ont-ils accès à vos données ? </h3>
                    <p>Les données collectées seront communiquées aux destinataires suivants : département RH, communication et/ou marketing. 
                       <br/>
                       Nous utilisons des cookies tiers sur le Site. Veuillez consulter la politique cookies pour plus d’information. 
                       <br/>
                       Si nous réalisons une opération sur notre société (cession, levée de fonds…), nous transférons également vos données au tiers impliqué et à ses conseils.
                       <br/>
                       Dans ce cas, vos données seront traitées par un nouveau responsable de traitement, à savoir l’entité qui a reçu vos données. Nous ne serons plus responsables de leur traitement. Il lui appartiendra de vous informer directement
                    </p>


                  

                    <h3>Vos données sont-elles transférées ou accessibles en dehors de l’Union Européenne ?   </h3>
                    <p>Si nous utilisons les services d’un prestataire qui se situe en dehors de l’Union Européenne, nous mettrons en place des garanties nécessaires et adaptées. </p>
                    <p style="font-style: italic;">Conformément aux articles 44 et suivants du RGPD, en cas de transfert des données en dehors de l’Union Européenne, il convient de recourir à des mesures prévues par le RGPD pour sécuriser ces transferts.</p>
                    <br /><br /><br /><br />

                    <h3>Informations et mise à jour de la politique de confidentialité</h3>
                    <p>Si nous actualisons ce document, nous vous en informerons par tous moyens. Nous vous inviterons également à prendre connaissance de la nouvelle version.</p>
                    <p style="font-style: italic;">
                      Dans le cadre de notre obligation de transparence, nous actualiserons ce document en cas de modification des traitements de vos données (ex : nouvelles finalités, nouvelle typologie de sous-traitants…). 
                      Vous devez prendre connaissance de ces modifications afin d’être informé des différents traitements de vos données.  
                    </p>
                    <p>Si nous avez des questions sur le contenu de ce document, nous sommes à votre disposition.</p>
                    <p>Vous pouvez nous contacter : </p>
                    <ul>
                      <li>Par courrier postal à : DUBBING BROTHERS 19 rue de la Montjoie - 93210 La Plaine Saint Denis </li>
                      <li>Par email à : <a class="linkButton" href="mailto:contact@dubbing-brother.com">contact@dubbing-brother.com</a></li>
                    </ul>
                    <br /><br /><br /><br />
                </div> -->

              </div>
          </div>
        </div>
      </main>
  </div>
</template>

<script>
export default {
  name: "PrivacyTermsPage",
}
</script>

