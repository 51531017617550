<template>
    <div id="cookiesPage" class="singlePage terms"> 
        <main>
          <div class="container-fluid px-4 m-0">
            <div class="row justify-content-center">
              <div class="col-11">
                <h2 class="mb-5">{{ $t('qljgt1x4') }}</h2>
                <div v-html="$t('zjh7fskx')"></div>

                <span class="cookieFrance" style="display:none;">
                  <p>Cette politique cookies vous informe de la façon dont Dubbing Brothers utilise des 
                    traceurs et autres outils pour collecter certaines données personnelles vous concernant. 
                    Si vous souhaitez avoir plus d'information sur la manière dont Dubbing Brothers traite vos données 
                    personnelles dans le cadre de son site internet, vous pouvez consulter la politique de 
                    <a class="privacy-policy-link" href="/#/privacy-terms">confidentialité du site</a>.
                  </p>

                  <div class="question one">
                    <h3>Qu'est-ce qu'un cookie ?</h3>
                    <p>Un cookie ou traceur est un petit fichier stocké par un serveur dans le terminal de navigation (ordinateur, téléphone, tablette.) d'un utilisateur lorsqu'il visite un 
                      site internet. Le cookie est ensuite associé à un domaine web (c'est-à-dire dans la majorité des cas à l'ensemble des pages d'un même site web).  
                      Ce fichier peut permettre d'identifier l'appareil de l'utilisateur à chaque fois qu'il visite un site internet.
                        La plupart des sites internet utilisent des cookies et c'est le cas du Site.<br/>
                        Un cookie va généralement contenir les informations suivantes : 
                      </p>
                      <ul>
                        <li><p>Le nom du site internet d'où il provient</p></li>
                        <li><p>Un numéro unique attaché au terminal de navigation </p></li>
                      </ul>
                  </div>

                  <div class="question two">
                    <h3>A quoi servent les cookies ?</h3>
                    <p>Dubbing Brothers utilise des cookies essentiels (notamment pour permettre d'identifier et corriger des bugs) ainsi des cookies non essentiels pour mesurer l'audience sur le Site.</p>
                  </div>

                  <div class="question three">
                    <h3>Est-il obligatoire d'accepter tous les cookies ?</h3>
                    <p>Les cookies dits « essentiels » seront installés automatiquement sur votre terminal puisqu'ils sont nécessaires au bon fonctionnement du site internet et permettent de limiter les disfonctionnements. Ces cookies contribuent également à rendre le site utilisable en activant des fonctions de 
                       base comme la navigation de page et l'accès aux zones sécurisées du site. Le site ne peut pas fonctionner correctement sans ces cookies. Vous ne pouvez pas refuser l'installation de ces cookies. 
                       <br/>
                       En revanche, vous êtes libre d'accepter l'utilisation des cookies optionnels, notamment pour la mesure d'audience et de performance. 
                       Si vous refusez les cookies, certaines pages ou fonctionnalités du Site peuvent ne pas fonctionner sur votre appareil.
                    </p>
                  </div>

                  <div class="question four">
                    <h3>Quels cookies sont utilisés sur le Site ?</h3>
                    <p>Dubbing Brothers utilise des cookies optionnels suivants : les cookies de mesure d'audience et de statistiques afin de connaître l'utilisation et les performances du site, l'origine des internautes, leur localisation, d'établir des statistiques, 
                      des volumes de fréquentation et d'utilisation de divers 
                       éléments (contenus visités, parcours…) aux fins d'améliorer l'intérêt et l'ergonomie des services proposés (les pages ou les rubriques les plus souvent consultées, les articles les plus lus ...).  
                       <br/>
                       Ces cookies servent également à mesurer le trafic de consultation du site. 
                       Les traceurs sont fournis par la solution Matomo, hébergée sur les serveurs de Dubbing Brothers directement.
                    </p>
                  </div>

                  <div class="question five">
                    <h3>Quels cookies sont utilisés sur le Site ?</h3>
                    <p>Dubbing Brothers utilise des cookies optionnels suivants : les cookies de mesure d'audience et de statistiques afin de connaître l'utilisation et les performances du site, l'origine des internautes, leur localisation, d'établir des statistiques, des volumes de fréquentation et d'utilisation de divers 
                       éléments (contenus visités, parcours…) aux fins d'améliorer l'intérêt et l'ergonomie des services proposés (les pages ou les rubriques les plus souvent consultées, les articles les plus lus ...).  
                       <br/>
                       Ces cookies servent également à mesurer le trafic de consultation du site. 
                       Les traceurs sont fournis par la solution Matomo, hébergée sur les serveurs de Dubbing Brothers directement.
                    </p>
                  </div>
``
                  <div class="question six">
                    <h3>Pendant combien de temps les cookies sont-ils stockés sur mon appareil ?</h3>
                    <p>Les cookies de session restent stockés sur votre appareil uniquement pendant la durée de session internet.   
                       <br/>
                       Les cookies persistants sont stockés sur le disque dur de votre appareil jusqu'à ce que vous les supprimiez ou qu'ils atteignent leur date d'expiration. 
                       Ces cookies peuvent, par exemple, être utilisés pour se souvenir de vos préférences quand vous naviguez sur le Site. Les données collectées via les 
                       cookies sont conservées pendant une durée de vingt-cinq (25) mois maximum et la durée de vie de ces cookies ne dépassera pas treize (13) mois à compter de leur dépôt sur votre terminal.
                    </p>
                  </div>

                  <div class="question seven">
                    <h3>Que puis-je faire pour gérer les cookies stockés sur mon appareil ?</h3>
                    <p>Si vous avez accepté dans votre logiciel de navigation l'enregistrement de cookies sur 
                       votre terminal, les cookies intégrés dans les pages et contenus que vous avez consultés pourront être stockés temporairement dans un espace dédié de votre terminal.   
                       <br/>
                       La plupart des navigateurs acceptent les cookies par défaut. 
                       Cependant, vous pouvez modifier le paramétrage et la gestion des cookies de votre navigateur, vous pouvez modifier les réglages directement depuis menu d'aide ou paramétrage du navigateur.
                       <br/>
                       Par exemple : 
                    </p>

                    <ul>
                      <li><p>Pour Internet Explorer : <a class="buttonAction p-2 py-0 rounded-2" href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies</a></p></li>
                      <li><p>Pour Safari : <a class="buttonAction p-2 py-0 rounded-2" href="https://support.apple.com/fr-fr/HT201265">https://support.apple.com/fr-fr/HT201265</a></p></li>
                      <li><p>Pour Google Chrome : <a class="buttonAction p-2 py-0 rounded-2" href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en&safe=on">https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en&safe=on</a></p></li>                    
                      <li><p>Pour Firefox : <a class="buttonAction p-2 py-0 rounded-2" href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">https://support.mozilla.org/fr/kb/activer-desactiver-cookies</a></p></li>
                      <li><p>Pour Opera : <a class="buttonAction p-2 py-0 rounded-2" href="https://help.opera.com/Windows/10.20/fr/cookies.html">https://help.opera.com/Windows/10.20/fr/cookies.html</a></p></li>
                    </ul>
                    <br/>
                    <p>
                      Nous attirons votre attention sur le fait que votre opposition à l'installation ou à l'utilisation d'un cookie sera prise en compte 
                      par l'installation d'un « cookie de refus » sur votre terminal. Aussi, veillez à ne pas supprimer ce cookie de refus si vous souhaitez que votre choix soit pris en compte.
                    </p>
                  </div>

                  <div class="question eight">
                    <h3>Quels sont vos droits sur les données collectées par les cookies ?</h3>
                    <p>Vous pouvez exercer les droits prévus dans la <a class="privacy-policy-link" href="/#/privacy-terms">politique de confidentialité</a> concernant le traitement de vos données. 
                       <br/>
                       Vous pouvez également retirer votre consentement à tout moment comme expliqué ci-dessus. 
                    </p>
                  </div>

                  <div class="question nine mb-5">
                    <h3>Informations et mise à jour de la politique cookies</h3>
                    <p>Dans le cadre de notre obligation de transparence, nous actualiserons ce document en cas de modification des traitements de vos données
                       (ex : nouvelles finalités, nouvelle typologie de sous-traitants…). Vous devez prendre connaissance de ces modifications afin d'être informé des différents traitements de vos données.  
                       <br/>
                       Si nous avez des questions sur le contenu de ce document, nous sommes à votre disposition.  
                       <br/>
                       Vous pouvez nous contacter : 
                       <ul>
                        <li>Par courrier postal à : DUBBING BROTHERS 19 rue de la Montjoie - 93210 La Plaine Saint Denis </li>
                        <li>Par email à : <a class="linkButton" href="mailto: contact@dubbing-brothers.com">contact@dubbing-brothers.com</a> </li>
                      </ul>
                    </p>
                  </div>
                </span>

                <span class="cookieEnglish" style="display:none;">
                  <p>This cookie policy informs you about how Dubbing Brothers uses trackers and other tools to collect certain personal data about you. 
                    If you would like more information on how Dubbing Brothers processes your personal data through its website, you can refer to the site's  
                    <a class="privacy-policy-link" href="/#/privacy-terms">privacy policy</a>.
                  </p>

                  <div class="question one">
                    <h3>What is a cookie ?</h3>
                    <p>A cookie or tracker is a small file stored by a server on the browsing terminal (computer, phone, tablet) of a user when they visit a website. 
                       The cookie is then associated with a web domain (typically, all pages of the same website). This file can be used to identify the user's device each time they visit a website. Most websites use cookies, and the same applies to this site.<br/>
                       A cookie generally contains the following information:
                      </p>
                      <ul>
                        <li><p>The name of the originating website,</p></li>
                        <li><p> A unique number associated with the browsing terminal. </p></li>
                      </ul>
                  </div>

                  <div class="question two">
                    <h3>Is it mandatory to accept all cookies?</h3>
                    <p>Dubbing Brothers uses essential cookies (including for bug identification and correction) as well as non-essential cookies to measure the audience on the Site..</p>
                  </div>

                  <div class="question three">
                    <h3>Is it mandatory to accept all cookies?</h3>
                    <p>The so-called "essential" cookies will be automatically installed on your device as they are necessary for the proper functioning of the website and help to limit malfunctions. These cookies also contribute to making the 
                       site usable by enabling basic functions such as page navigation and access to secure areas of the site. The site cannot function properly without these cookies. You cannot refuse the installation of these cookies. 
                       <br/>
                       However, you are free to accept the use of optional cookies, especially for audience measurement and performance purposes. If you refuse cookies, certain pages or features of the Site may not work on your device.
                    </p>
                  </div>

                  <div class="question four">
                    <h3>What cookies are used on the Site ?</h3>
                    <p>Dubbing Brothers uses the following optional cookies: audience measurement and statistics cookies to understand the usage and performance of the site, the origin of users, their location, and to generate statistics on the 
                       volume of visits and use of various elements (visited content, navigation paths, etc.) in order to improve the interest and usability of the services provided (most frequently visited pages or sections, most read articles, etc.).  
                       <br/>
                       These cookies are also used to measure site traffic.
                       The trackers are provided by the Matomo solution, hosted on Dubbing Brothers' servers directly.
                    </p>
                  </div>

                  <div class="question five">
                       <h3>How long are cookies stored on my device ?</h3>
                       <p>Session cookies are stored on your device only for the duration of the internet session. 
                       <br/>
                       Persistent cookies are stored on the hard drive of your device until you delete them or they reach their expiration date. These cookies may, for example, be used to remember 
                       your preferences when you browse the Site. The data collected via cookies is kept for a maximum period of twenty-five (25) months, and the lifespan of these cookies will not exceed thirteen (13) months from their deposit on your device.
                       </p>
                  </div>

                  <div class="question six">
                    <h3></h3>
                    <p>
                       <br/>
                   
                    </p>
                  </div>

                  <div class="question seven">
                    <h3>What can I do to manage cookies stored on my device?</h3>
                    <p>If you have accepted the storage of cookies on your device through your browser settings, the cookies embedded in the pages and content you have viewed may be temporarily stored in a dedicated space on your device.   
                       <br/>
                       Most browsers accept cookies by default. However, you can modify the settings and management of cookies in your browser. You can directly modify the settings from the browser's help menu or settings.
                       <br/>
                       For example: 
                    </p>

                    <ul>
                      <li><p>For Internet Explorer : <a class="buttonAction p-2 py-0 rounded-2" href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies</a></p></li>
                      <li><p>For Safari : <a class="buttonAction p-2 py-0 rounded-2" href="https://support.apple.com/fr-fr/HT201265">https://support.apple.com/fr-fr/HT201265</a></p></li>
                      <li><p>For Google Chrome : <a class="buttonAction p-2 py-0 rounded-2" href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en&safe=on">https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en&safe=on</a></p></li>                    
                      <li><p>For Firefox : <a class="buttonAction p-2 py-0 rounded-2" href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">https://support.mozilla.org/fr/kb/activer-desactiver-cookies</a></p></li>
                      <li><p>For Opera : <a class="buttonAction p-2 py-0 rounded-2" href="https://help.opera.com/Windows/10.20/fr/cookies.html">https://help.opera.com/Windows/10.20/fr/cookies.html</a></p></li>
                    </ul>
                    <br/>
                    <p>
                      Please note that your opposition to the installation or use of a cookie will be taken into account by the installation of a "refusal cookie" on your device. Therefore, please ensure not to delete this refusal cookie if you want your choice to be taken into account.
                    </p>
                  </div>

                  <div class="question eight">
                    <h3>What are your rights regarding the data collected by cookies ?</h3>
                    <p>You can exercise the rights provided in the <a class="privacy-policy-link" href="/#/privacy-terms">privacy policy</a> regarding the processing of your data. 
                      <br/>
                       You can also withdraw your consent at any time as explained above.
                    </p>
                  </div>

                  <div class="question nine mb-5">
                    <h3>Information and updating of the cookie policy </h3>
                    <p>In accordance with our transparency obligation, we will update this document in the event of changes to the 
                      processing of your data (e.g., new purposes, new types of subprocessors, etc.). You should review these modifications to be informed about the various processing of your data.
                       <br/>
                       If you have any questions regarding the content of this document, we are at your disposal. 
                       <br/>
                       You can contact us: 
                       <ul>
                        <li>By postal mail at: DUBBING BROTHERS 19 rue de la Montjoie - 93210 La Plaine Saint Denis </li>
                        <li>By email at: <a class="linkButton" href="mailto: contact@dubbing-brothers.com">contact@dubbing-brothers.com</a> </li>
                      </ul>
                    </p>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </main>
    </div>
</template>

<script>
export default {
  name: "CookieTermsPage",
}
</script>
