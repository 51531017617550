<template>
    <div id="newsPage" class="singlePage">
    <img class="wave-background" src="../assets/img/background_lines_wave.svg">
    <main>
        <div class="background first"></div>
        <div class="container-fluid px-4 m-0">
            <div class="row justify-content-center">
                <div class="col-11 p-0">
                    <h1>{{ $t('jxoqwkvn') }}</h1>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mb-5">
            <div class="col-11 mb-5">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3" v-for="post in this.morethanfive" v-bind:key="post.id">   
                        <div class="contentNews">
                            <div>
                                <div class="top">
                                    <div class="imgWrapper" @click="navigate(post.id)" style="cursor: pointer;">
                                        <img :src=post.image_url alt="">
                                    </div>

                                    <p class="date"><small>{{ $t(post.date) }}</small></p>

                                    <h3>{{ $t(post.title) }}</h3>
                                    <div class="description">
                                        <p v-html="$t(post.short_description)"></p>
                                    </div>
                                </div>

                                <div @click="navigate(post.id)" v-if="post.id != 1" class="buttonAction">{{$t('bzff5ubj')}}</div>
                                <div @click="navigate(post.id)" v-if="post.id == 1" class="buttonAction">{{$t('2sw557tp')}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">   
                        <div class="contentNews">
                            <div class="imgWrapper anniversary">
                                <img src="@/assets/img/front/VoeuxDB.gif" alt="happy new year">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3" v-for="post in this.lessthanfive" v-bind:key="post.id">   
                        <div class="contentNews">
                            <div>
                                <div class="top">
                                    <div class="imgWrapper" @click="navigate(post.id)" style="cursor: pointer;">
                                        <img :src=post.image_url alt="">
                                    </div>

                                    <p class="date"><small>{{ $t(post.date) }}</small></p>

                                    <h3>{{ $t(post.title) }}</h3>
                                    <div class="description">
                                        <p v-html="$t(post.short_description)"></p>
                                    </div>
                                </div>

                                <div @click="navigate(post.id)" v-if="post.id != 1" class="buttonAction">{{$t('bzff5ubj')}}</div>
                                <div @click="navigate(post.id)" v-if="post.id == 1" class="buttonAction">{{$t('2sw557tp')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="background"></div>
        <div class="waveTopWrapper aos-animate animate__fadeIn animate__delay-4s"></div>
    </main>
    </div>
</template>

<script>
import configStatic from '@/boot/configStatic';

export default {
name: "BlogPage",
    data() {
        return {
            posts: configStatic.BLOG_POSTS,
            lessthanfive: [],
            morethanfive: [],
        }
    },
    mounted() {
        this.lessthanfive = this.itemsWithIdLessThanFive(),
        this.morethanfive = this.otherItems()
    },
    methods: {
        navigate(id){
            this.$router.push({ path: "/blog/post/"+ id });
        },
        itemsWithIdLessThanFive() {
            return this.posts.filter(item => item.id < 5);
        },
        otherItems() {
            return this.posts.filter(item => item.id >= 6);
        }
    }
}
</script>