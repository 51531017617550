<template>
    <div id="emploiPage" class="singlePage">
        <main>
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-11">
                    <div class="imgWrapper textOver accordion-body mb-4">
                        <img src="../assets/img/front/berlin_003.jpg" alt="dubbing interview1 stage" style="opacity: 0.5;">
                        <h4>{{$t('j7l5n8iw')}}</h4>
                    </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-11">
                    <p><span v-html="$t('4qg7mekb')"></span></p>
                </div>
                <div class="col-11">
                    <div class="row jobListWrapper g-3">
                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                            <ul class="nav nav-tabs" id="jobList" role="tablist">
                                <h4 v-if="jobTitles.length > 0">{{ $t('xb54tnac') }}</h4>
                                <li class="nav-item mb-3">
                                    <!-- Comediene -->
                                    <a class="nav-link voicematchGo" href="https://www.voicematch.fr" target="_blank">{{ $t('uo1a9vt8')}}</a>
                                </li>

                                <li class="nav-item mb-3" role="presentation" v-for="(jobTitle, index) in jobTitles" v-bind:key="jobTitle.id">
                                    <button class="nav-link" type="button" role="tab" data-bs-toggle="tab"
                                    :class="{active: index == 0}"
                                    @click="handleJobApplicationForm(jobTitle.id, false, false)"
                                    :data-bs-target="`#tab-${jobTitle.id}`" 
                                    :aria-controls="`tab-${jobTitle.id}`" 
                                    aria-selected="true"><span v-html="jobTitle.title"></span></button>
                                </li>

                                <h4 v-if="defaultJobTitles.length > 0">{{ $t('vw81udij') }}</h4>
                                <li class="nav-item" role="presentation" v-for="(jobTitle, index) in defaultJobTitles" v-bind:key="jobTitle.id">
                                    <button class="nav-link" type="button" role="tab" data-bs-toggle="tab"
                                    :class="{active: index == 0 && jobTitles.length == 0}"
                                    @click="handleJobApplicationForm(jobTitle.id, true, true)"
                                    :data-bs-target="`#tab-${jobTitle.id}`" 
                                    :aria-controls="`tab-${jobTitle.id}`" 
                                    aria-selected="true">{{$t('9u8kzjcj')}}
                                    <!-- {{jobTitle.title}} -->
                                    </button>
                                </li>

                            </ul>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg col-xl" v-show="!isDefaultJob && jobs.length > 0">
                            <div class="tab-content formWrapper d-flex justify-content-center align-items-center" v-if="jobsLoading">
                                <div class="fa-3x">
                                    <i class="fas fa-circle-notch fa-spin" style="color:var(--gold);"></i>
                                </div>
                            </div>
                            <div class="tab-content" id="jobContent"> 
                                    <div class="tab-pane fade" role="tabpanel" :tabindex="index"
                                        v-for="(job, index) in jobs" v-bind:key="job.id"
                                        :class="{show: index == 0, active: index == 0}" 
                                        :id="`tab-${job.id}`" 
                                        :aria-labelledby="`tab-${job.id}`">
                                        <div class="d-flex flex-column justify-content-between">
                                            <div class="jobContentDescription">
                                                <h3 class="my-3">{{$t('1qh1ftgd') }}</h3>
                                                <p><span v-html="job.description"></span></p>
                                            </div>
                                            <button class="btn btn-primary" @click="handleJobApplicationForm(job.id, true, false)" type="button">{{$t('9u8kzjcj')}}</button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                        <div v-bind:class="{'col-sm-12 col-md-12 col-lg-3 col-xl-3': !isDefaultJob, 'col-sm-12 col-md-12 col-lg col-xl': isDefaultJob}"
                             v-show="displayForm">
                            <div class="formWrapper d-flex justify-content-center align-items-center" v-if="formLoading">
                                <div class="fa-3x">
                                    <i class="fas fa-circle-notch fa-spin" style="color:var(--gold);"></i>
                                </div>
                            </div>
                            <div id="formWrapper" class="formWrapper" v-show="!formLoading">
                                <form class="needs-validation" novalidate>
                                    <div class="mb-1">
                                        <label for="validationCustom01" class="form-label">{{$t('0vjr3a0s')}}*</label>
                                        <input type="text" class="form-control" id="validationCustom01" v-model="newApplication.lastname" :placeholder="$t('0vjr3a0s')" required>
                                        <div class="valid-feedback">{{$t('51v0cnc9')}}</div>
                                    </div>

                                    <div class="mb-1">
                                        <label for="validationCustom02" class="form-label">{{$t('nerc2brw')}}*</label>
                                        <input type="text" class="form-control" id="validationCustom02" v-model="newApplication.firstname" :placeholder="$t('nerc2brw')" required>
                                        <div class="valid-feedback">{{$t('51v0cnc9')}}</div>
                                    </div>

                                    <div class="mb-1">
                                        <label for="validationCustom03" class="form-label">{{ $t('jw37yx2h') }}*</label>
                                        <input type="email" class="form-control" id="validationCustom03" v-model="newApplication.email" :placeholder="$t('jw37yx2h')" required>
                                        <div class="invalid-feedback">{{ $t('ye12kplg') }}</div>
                                    </div>

                                    <div class="mb-1">
                                        <label for="validationCustom03" class="form-label">{{ $t('42z0momt') }}</label>
                                        <input type="text" class="form-control" id="validationCustom03" v-model="newApplication.phone" :placeholder="$t('42z0momt')" required>
                                        <div class="invalid-feedback">{{$t('0vlyw6js')}}</div>
                                    </div>

                                    <div class="mb-1">
                                        <label for="validationCustom05" class="form-label">{{ $t('ma7jpf63') }}</label>
                                        <input type="text" class="form-control" id="validationCustom05" v-model="newApplication.linkedin" placeholder="www.linkedin.com/rock">
                                        <div class="invalid-feedback">{{ ('w3x1i8yw') }}</div>
                                    </div>

                                    <div class="mb-1">
                                        <label for="validationCustom06" class="form-label">{{ $t('hpaeurvf') }}*</label>
                                        <br/>
                                        <input type="button" class="form-control form-control-file" :value="this.newApplication.cvFile ? this.newApplication.cvFile.name : $t('4ppoan8q')" onclick="document.getElementById('cvInput').click();" :class="this.newApplication.cvFile ? 'file-selected' : ''" />
                                        <input type="file" class="form-control" aria-label="file example" @change="handleCVFile($event)" accept=".doc,.docx,.odt,.pdf" ref="cvInput" id="cvInput" required style="display: none;">
                                        <div class="invalid-feedback">{{$t('bv2yfwh4')}}</div>
                                    </div>

                                    <div class="mb-1">
                                        <label for="validationCustom06" class="form-label">{{$t('4sw7frv8')}}</label><br/>
                                        <input type="button" class="form-control form-control-file" :value="this.newApplication.coverLetterFile ? this.newApplication.coverLetterFile.name : $t('4ppoan8q')" 
                                        onclick="document.getElementById('coverLeterInput').click();" :class="this.newApplication.coverLetterFile ? 'file-selected' : ''"/>
                                        <input type="file" class="form-control" aria-label="file example" @change="handleCoverLetterFile($event)" accept=".doc,.docx,.odt,.pdf" ref="coverLeterInput" id="coverLeterInput" required style="display: none;">
                                        <div class="invalid-feedback">{{$t('bv2yfwh4')}}</div>
                                    </div>

                                    <div>
                                        <div class="d-flex flex-columnalign-items-center recruit">
                                        <!-- <input class="form-check-input" type="checkbox" value="" id="invalidCheck" v-model="termsAccepted" required> -->
                                        <label for="invalidCheck">
                                            {{ $t('nktkgbnf') }}<br>
                                            {{ $t('c20xt3bb') }}&nbsp;<router-link to="/privacy-terms">{{ $t('oltochoi') }}</router-link>.
                                        </label>
                                        <div class="invalid-feedback">{{$t('fi6gfa35')}}</div>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="row py-3">
                                            <div class="col-lg-6 col" >
                                                <div class="d-flex align-items-center justify-content-start my-1" @click="getChallenge()">
                                                    <img :src="captchaImage">
                                                    <div class="ms-2 d-flex align-items-center justify-content-center" >
                                                        <i v-if="captchaLoading" class="fas fa-circle-notch fa-spin fa-fw" style="color:var(--gold);"></i>
                                                        <i v-if="!captchaLoading && captchaImage" class="fa-solid fa-arrows-rotate redoCaptcha fa-fw" ></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col">
                                                <input type="text" class="form-control my-1" v-model="newApplication.captchaCode" placeholder="Captcha code" required>
                                            </div>
                                        </div>
                                    </div>
                                        <button class="btn btn-primary" type="button" @click="postJobApplication()" :disabled="!newApplication.captchaCode">{{$t('9u8kzjcj')}}</button>
                                        <!-- :disabled="!termsAccepted || !newApplication.captchaCode" -->
                                        <!-- <div v-if="showJobSuccess">
                                        Nice done
                                        <button class="btn btn-primary" type="button" @click="closeJobSuccess()">Close</button>
                                        </div> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </main>
        <ErrorModal :message="errorMessage" :openModal="errorModalOpen" :resetProps="handleErrorModalResetProps" />
        <SuccessModal :message="successMessage" :openModal="successModalOpen" :resetProps="handleSuccessModalResetProps" />
    </div>
</template>

<script>
import $axios from '@/boot/axios';
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/SuccessModal.vue';

import configStatic from '@/boot/configStatic';

export default {
    name: "JobsPage",
    components: {
        ErrorModal,
        SuccessModal
    },
    data() {
        return {
            jobs: [],
            jobTitles: [],
            defaultJobTitles: [],
            displayForm: false,
            isDefaultJob: false,
            selectedJobId: null,
            captchaImage: null,
            formLoading: false,
            jobsLoading: false,
            captchaLoading: false,
            successMessage: 'mlub940v',
            newApplication: {
                lastname: null,
                firstname: null,
                email: null,
                phone: null,
                linkedin: null,
                cvFile: null,
                coverLetterFile: null,
                captchaCode: null
            },
            errorMessage: null,
            errorModalOpen: false,
            successModalOpen: false,
            termsAccepted: false
        }
    },
    beforeMount() {
        this.getJobs();
    },
    methods: {
        getJobs() {
            this.jobsLoading = true;
            $axios.get(`/jobs/${configStatic.SELECTED_BRANCH_ID}`).then(({data: response}) => {
                let jobs = response.jobs;
                if (jobs.length > 0) {
                    jobs.forEach(job => {
                        this.jobTitles.push({
                            id: job.id,
                            title: job.title
                        });
                    });
                }
                let defaultJobs = response.defaultJobs;
                if (defaultJobs.length > 0) {
                    defaultJobs.forEach(job => {
                        this.defaultJobTitles.push({
                            id: job.id,
                            title: job.title
                        });
                    });
                }
                this.jobs = jobs.concat(defaultJobs);
                
                if (this.jobs.length > 0) {
                    this.handleJobApplicationForm(this.jobs[0], response.jobs.length == 0, response.jobs.length == 0)
                }

                this.jobsLoading = false;
            });
        },
        getChallenge() {
            this.captchaLoading = true;
            $axios.get('/captcha-challenge').then((response) => {
                this.captchaLoading = false;
                this.captchaImage = response.data.captchaImage;
            }).catch(error => {
                this.captchaLoading = false;
                this.errorModalOpen = true;
                this.errorMessage = error.message;
            });
        },
        handleJobApplicationForm(jobId, displayForm, isDefaultJob) {
            this.isDefaultJob = isDefaultJob;
            this.selectedJobId = jobId;
            this.displayForm = displayForm;
            
            if (displayForm) {
                this.newApplication.lastname = null;
                this.newApplication.firstname = null;
                this.newApplication.email = null;
                this.newApplication.cvFile = null;
                this.newApplication.phone = null;
                this.newApplication.linkedin = null;
                this.newApplication.coverLetterFile = null;
                this.$refs.cvInput.value = null;
                this.$refs.coverLeterInput.value = null;

                this.captchaLoading = false;
                this.formLoading = false;
                this.captchaImage = null;
                this.getChallenge();
            }
        },
        postJobApplication() {
            this.formLoading = true;
            if (this.selectedJobId) {

                const form = new FormData();
                form.append('lastname', this.newApplication.lastname);
                form.append('firstname', this.newApplication.firstname);
                form.append('email', this.newApplication.email);
                form.append('phone', this.newApplication.phone);
                if (this.newApplication.linkedin)
                    form.append('linkedin', this.newApplication.linkedin);
                form.append('captchaCode', this.newApplication.captchaCode);
                if (this.newApplication.coverLetterFile)
                    form.append('coverLetterFile', this.newApplication.coverLetterFile);
                form.append('cvFile', this.newApplication.cvFile);

                $axios.post(`/job-application/${this.selectedJobId}`, form).then(() => {
                    this.displayForm = false;
                    this.newApplication = {
                        lastname: null,
                        firstname: null,
                        email: null,
                        phone: null,
                        linkedin: null,
                        cvFile: null,
                        coverLetterFile: null,
                        captchaCode: null
                    };
                    this.captchaImage = null;
                    this.formLoading = false;
                    this.successModalOpen = true;
                }).catch(error => {
                    this.formLoading = false;
                    this.errorModalOpen = true;
                    this.errorMessage = error.message;
                });
            }
        },
        handleCVFile(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.newApplication.cvFile = files[0];
        },
        handleCoverLetterFile(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.newApplication.coverLetterFile = files[0];
        },
        handleErrorModalResetProps() {
            this.errorModalOpen = false;
            this.errorMessage = null;
        },
        handleSuccessModalResetProps() {
            this.successModalOpen = false;
            this.successMessage = 'mlub940v';
        }
    }
}
</script>
<style scoped>
    .recruit label {
        line-height: 15px;
        font-size: calc(0.4vw + 8px);
        width: 100%;
    }

    .recruit label a {
        text-decoration: underline;
        cursor: pointer;
        color: var(--gold);

    }
    .recruit label a:hover {
        color: #765c39;
    }

    .textOver {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }

    .textOver h4 {
        position: absolute;
        font-size: calc(5vw + 15px);
        font-weight: 400;
        /* font-family: 'qwitcher'; */
        text-shadow: 0px 0px 20px #f1b049, 0px 0px 20px #f1b049, 0px 0px 20px #f1b049;
    }

    @media screen and (max-width: 1024px) {
        .textOver h4 {
            font-size: calc(7vw + 15px);
            text-align: center;
            width: 60%;
            line-height: 75px;
        }
    }

</style>