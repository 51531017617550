import { createI18n } from 'vue-i18n';
import $axios from './axios';
import session from './session';
import config from './config';

export default async () => {
  const messages = {
    fr: {},
    en: {},
    de: {},
    it: {},
    es: {},
  };

  const escapeSpecialCharacter = (str) => {
    return str ? str.replaceAll('@', "{'@'}") : str;
  };

  const { data } = await $axios.get(`translations/${config.APP_ID}`);

  for (let uniqueId in data) {
    messages['fr'][uniqueId] = escapeSpecialCharacter(data[uniqueId].fr);
    messages['en'][uniqueId] = escapeSpecialCharacter(data[uniqueId].en);
    messages['de'][uniqueId] = escapeSpecialCharacter(data[uniqueId].de);
    messages['it'][uniqueId] = escapeSpecialCharacter(data[uniqueId].it);
    messages['es'][uniqueId] = escapeSpecialCharacter(data[uniqueId].es);
  }

  return createI18n({
    locale: session.getLanguage(),
    fallbackLocale: config.DEFAULT_LANGUAGE,
    messages: messages,
  });
};
